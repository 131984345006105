import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import { ApiUrl } from '../../../../../config/Api'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { getEnv, parseDBDateToJSFormat } from "../../../../../../helpers/procurex";
import { useIntl } from "react-intl";
import SelectX from '../../../../../components/shared/form/SelectX'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import SwalX from '../../../../../components/shared/SwalX'
import { InputNumber } from "rsuite";
import { Field, Form, Formik } from 'formik'
import { toast } from 'react-toastify'
import { useAuth } from "../../../../../../app/modules/auth";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import DateRangePickerX from '../../../../../components/shared/form/DateRangePickerX'
import axios from 'axios'

function formatNumber(value) {
    if (!value) return '';

    return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}


const TransportationMasterDataStockviewList = () => {
    const { currentUser } = useAuth();
    const intl = useIntl();
    const tablecolumns = [
        {
            Header: "Name",
            accessor: "name",
            width: 180,
        },
        {
            Header: "Capacity",
            accessor: "capacity",
            width: 120,
            Cell: ({ value }) => {
                return formatNumber(value);
            }
        },
        {
            Header: "UOM",
            accessor: "uom",
            width: 100,
        },
        {
            Header: "NOMOR IMO",
            accessor: "imo",
            width: 120,
        },
        {
            Header: "ETA Estimasi",
            accessor: "eta_estimasi",
            width: 120,
        },
        {
            Header: "PORT DESTINATION",
            accessor: "port_destination",
            width: 120,
        },
        {
            Header: "Komoditas",
            accessor: "komoditas",
            width: 120,
        },
        {
            Header: "Category",
            accessor: "category",
            width: 120,
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 200,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-primary'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItemDetail(row);
                                    }}
                                >
                                    <i className='fa fa-eye' aria-hidden="true"></i>
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-warning'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row);
                                    }}
                                >
                                    <i className='fa fa-pencil' aria-hidden="true"></i>
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-danger'
                                    onClick={() => {
                                        setItemId(row.id)
                                        setImo(row.imo)
                                        setIsConfirmDeleteOpen(true)
                                    }}
                                >
                                    <i className={'fa fa-trash'} />
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            },
        },
    ];

    const loadItem = () => {

    }

    useEffect(() => {
        loadItem();
    }, [])

    const [formIndex, setFormIndex] = useState(0);

    const [key, setKey] = useState(0)
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const [id, setId] = useState('')
    let [itemId, setItemId] = useState(0)
    let [imoVessel, setImo] = useState(0)
    const [item, setItem] = useState<any>(null)
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowModalDetail, setIsShowModalDetail] = useState(false)
    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
    const excludedRoles = ['admin_anper'];

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategory = (selectedValue: any) => {
        setSelectedCategory(selectedValue);
    };

    const [totalCapacity, setTotalCapacity] = useState(0);
    const [HargaPerolehan, setHargaPerolehan] = useState(0);

    const handleOnTotalCapacity = (score: number) => {
        setTotalCapacity(score)
    }
    const handleOnHarga = (score: number) => {
        setHargaPerolehan(score)
    }

    const deleteItem = async () => {
        try {
            setIsLoadingDelete(true)
            const transportationResponse = await axios.get(getEnv("stockview") + `/transportation/${itemId}`);
            const transportationData = transportationResponse.data.data;

            const newData = [{
                name: transportationData.name,
                imo: transportationData.imo,
                category: transportationData.category,
                capacity: transportationData.capacity,
                uom: transportationData.uom ? transportationData.uom : transportationData.capacity ? 'TON' : '',
                harga_perolehan: transportationData.harga_perolehan,
                currency: transportationData.currency || 'IDR',
                komoditas: transportationData.komoditas,
                vendor: transportationData.vendor,
                awarding: transportationData.awarding,
                eta_estimasi: transportationData.eta_estimasi,
                destination_estimasi: transportationData.destination_estimasi,
                laycan_date: transportationData.laycan_date,
                port_load: transportationData.port_load,
                port_destination: transportationData.port_destination
            }];

            await axios.post(getEnv("stockview") + `/vessel-history-log`, newData);

            const response: any = await axios.delete(getEnv("stockview") + `/transportation/${itemId}`)

            let responseAddFleet;
            try {
                if (imoVessel) {
                    responseAddFleet = await axios.get(getEnv("vessel_add_fleet") + `?imo=${imoVessel}&active=0&protocol=jsono`);
                } else {
                    console.log('vessel dont have imo');
                }
            } catch (error: any) {
                console.error("Add fleet request failed", error);
            }

            if (response.status === 200) {
                setFormIndex(formIndex + 1)
                setIsConfirmDeleteOpen(false)
                toast.success(response?.data?.meta?.message, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                })
                loadItem()
            }
        } catch (error: any) {
            console.error(error)
            toastError(error.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsLoadingDelete(false)
        }
    }

    const submit = async (values: any) => {
        try {
            const newData = {
                name: values.name,
                imo: values.imo,
                category: selectedCategory ? selectedCategory : item?.category,
                capacity: totalCapacity ? totalCapacity : item?.capacity,
                uom: item?.uom ? item?.uom : item?.capacity ? 'TON' : '',
                harga_perolehan: HargaPerolehan ? HargaPerolehan : item?.harga_perolehan,
                currency: values.currency || item?.currency ? values.currency || item?.currency : 'IDR',
                komoditas: values.komoditas,
                vendor: values.vendor,
                awarding: values.awarding,
                eta_estimasi: values.eta_estimasi,
                destination_estimasi: values.destination_estimasi,
                laycan_date: values.laycan_date,
                port_load: values.port_load,
                port_destination: values.port_destination,
            };

            const newDataVesselHistory = [{
                name: item?.name,
                imo: item?.imo,
                category: selectedCategory ? selectedCategory : item?.category,
                capacity: totalCapacity ? totalCapacity : item?.capacity,
                uom: item?.uom ? item?.uom : item?.capacity ? 'TON' : '',
                harga_perolehan: item?.harga_perolehan,
                currency: item?.currency,
                komoditas: item?.komoditas,
                vendor: item?.vendor,
                awarding: item?.awarding,
                eta_estimasi: item?.eta_estimasi,
                destination_estimasi: item?.destination_estimasi,
                laycan_date: item?.laycan_date,
                port_load: item?.port_load,
                port_destination: item?.port_destination,
            }];


            let responseAddFleet;
            try {
                const imo = values.imo;

                if (!imo) {
                    await axios.post(getEnv("stockview") + `/vessel-history-log`, newDataVesselHistory);
                    responseAddFleet = await axios.get(getEnv("vessel_add_fleet") + `?imo=${item.imo}&active=0&protocol=jsono`);
                } else {
                    responseAddFleet = await axios.get(getEnv("vessel_add_fleet") + `?imo=${values.imo}&active=1&protocol=jsono`);
                }
            } catch (error: any) {
                console.error("Add fleet request failed", error);
            }

            const response = await axios.put(getEnv("stockview") + `/transportation/${id}`, newData)

            let apiUrl = process.env.REACT_APP_API_VESSEL;
            const dataVessel = await fetch(apiUrl).then((response) => response.json());

            const vessel = dataVessel.DATA.find((v: any) => v.IMO === values.imo);
            if (!vessel) {
                throw new Error('Vessel with matching IMO not found');
            }

            const vesselData = [
                {
                    mmsi: vessel.MMSI,
                    imo: vessel.IMO,
                    ship_id: vessel.SHIP_ID,
                    lat: vessel.LAT,
                    lon: vessel.LON,
                    eta: vessel.ETA,
                    destination: vessel.DESTINATION,
                    flag: vessel.FLAG,
                    draught: vessel.DRAUGHT,
                    type_name: vessel.TYPE_NAME,
                    ship_country: vessel.SHIP_COUNTRY,
                    ship_class: vessel.SHIP_CLASS,
                    last_port: vessel.LAST_PORT,
                    last_port_id: vessel.LAST_PORT_ID,
                    last_port_unlocode: vessel.LAST_PORT_UNLOCODE,
                    last_port_country: vessel.LAST_PORT_COUNTRY,
                    next_port_id: vessel.NEXT_PORT_ID,
                    next_port_unlocode: vessel.NEXT_PORT_UNLOCODE,
                    next_port_name: vessel.NEXT_PORT_NAME,
                    next_port_country: vessel.NEXT_PORT_COUNTRY,
                    speed: vessel.SPEED,
                    heading: vessel.HEADING,
                    course: vessel.COURSE,
                    status: vessel.STATUS,
                    timestamp: vessel.TIMESTAMP,
                    dsrc: vessel.DSRC,
                    utc_seconds: vessel.UTC_SECONDS,
                    market: vessel.MARKET,
                    shipname: vessel.SHIPNAME,
                    shiptype: vessel.SHIPTYPE,
                    callsign: vessel.CALLSIGN,
                    length: vessel.LENGTH,
                    width: vessel.WIDTH,
                    grt: vessel.GRT,
                    dwt: vessel.DWT,
                    year_built: vessel.YEAR_BUILT,
                    rot: vessel.ROT,
                    ais_type_summary: vessel.AIS_TYPE_SUMMARY,
                    l_fore: vessel.L_FORE,
                    w_left: vessel.W_LEFT,
                    last_port_time: vessel.LAST_PORT_TIME,
                    current_port: vessel.CURRENT_PORT,
                    current_port_id: vessel.CURRENT_PORT_ID,
                    current_port_unlocode: vessel.CURRENT_PORT_UNLOCODE,
                    current_port_country: vessel.CURRENT_PORT_COUNTRY,
                    eta_calc: vessel.ETA_CALC,
                    eta_updated: vessel.ETA_UPDATED,
                    distance_to_go: vessel.DISTANCE_TO_GO,
                    distance_travelled: vessel.DISTANCE_TRAVELLED,
                    avg_speed: vessel.AVG_SPEED,
                    max_speed: vessel.MAX_SPEED,
                }
            ];

            const responseAddFleetToVessel = await axios.post(getEnv("stockview") + `/vessel-repository`, vesselData);

            if (
                (response.status === 200 || response.status === 201) &&
                (!responseAddFleet || (responseAddFleet.status === 200 || responseAddFleet.status === 201)) &&
                (responseAddFleetToVessel.status === 200 || responseAddFleetToVessel.status === 201)
            ) {
                if (!newData.imo) {
                    toast.success("Fleet item updated successfully with item.imo.");
                }
                else if (responseAddFleet?.data?.success?.[0]?.code === "s7") {
                    toast.success(responseAddFleet.data.success[0].detail || "Fleet item updated successfully");
                } else {
                    toast.success(response.data.meta.message);
                }

                setId('');
                setIsShowModal(false);
                setFormIndex(formIndex + 1);
            }
        } catch (error: any) {
            if (!values.imo) {
                toast.success("Fleet item updated successfully with no IMO.");
            }

            setId('');
            setIsShowModal(false);
            setFormIndex(formIndex + 1);
            // toast.error(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
        } finally {
            setIsConfirmDialog(false)
            setIsShowDetail(false)
        }
    }

    const [initialValues, setInitialValues] = useState<any>({
        name: item?.name || '',
        imo: item?.imo || '',
        category: item?.category || '',
        capacity: item?.capacity || '0',
        uom: item?.uom || '0',
        harga_perolehan: item?.harga_perolehan || '0',
        currency: item?.currency ? item?.currency : 'IDR',
        komoditas: item?.komoditas,
        vendor: item?.vendor,
        awarding: item?.awarding,
        eta_estimasi: item?.eta_estimasi,
        laycan_date: item?.laycan_date,
        port_load: item?.port_load,
        port_destination: item?.port_destination,
    })

    const openModalWithItem = (item: any) => {
        setInitialValues({
            name: item?.name || '',
            imo: item?.imo || '',
            category: item?.category || '',
            capacity: item?.capacity || '0',
            uom: item?.uom,
            harga_perolehan: item?.harga_perolehan,
            currency: item?.currency,
            komoditas: item?.komoditas,
            vendor: item?.vendor,
            awarding: item?.awarding,
            eta_estimasi: item?.eta_estimasi,
            laycan_date: item?.laycan_date ? JSON.parse(item.laycan_date).map(date => new Date(date)) : [],
            port_load: item?.port_load,
            port_destination: item?.port_destination,
        });
        setItem(item);
        setIsShowModal(true);
    };

    const openModalWithItemDetail = (item: any) => {
        setItem(item);
        setIsShowModalDetail(true);
    };

    let role = currentUser.has_roles;

    //laycan date format
    const formatDateLaycan = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const laycanDates = item?.laycan_date ? JSON.parse(item.laycan_date) : [];
    const formattedStartDate = laycanDates[0] ? formatDateLaycan(laycanDates[0]) : '';
    const formattedEndDate = laycanDates[1] ? formatDateLaycan(laycanDates[1]) : '';
    //end laycan date format

    return (
        <>
            <SwalX
                title={intl.formatMessage({ id: 'DATATABLE.CONFIRM_DELETE' })}
                message={intl.formatMessage({ id: 'DATATABLE.MESSAGE_DELETE' })}
                show={isConfirmDeleteOpen}
                loading={isLoadingDelete}
                onHide={() => setIsConfirmDeleteOpen(false)}
                onSubmit={() => deleteItem()}
            />

            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header>Edit Kedatangan {item?.name}</Modal.Header>
                <Modal.Body>
                    <Formik initialValues={initialValues} onSubmit={submit} enableReinitialize>
                        {({ values, setFieldValue }) => (
                            <Form>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Nomor IMO
                                            </label>
                                            <Field className='form-control' name="imo" placeholder="input imo.." value={values.imo} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='required form-label'>
                                                Name
                                            </label>
                                            <Field className='form-control' name="name" placeholder="input name.." value={values.name} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='required form-label'>
                                                Capacity
                                            </label>
                                            <InputNumber
                                                defaultValue={formatNumber(initialValues.capacity)}
                                                min={0}
                                                name="capacity"
                                                className={"w-100 text-end"}
                                                onChange={(value: any) => {
                                                    handleOnTotalCapacity(value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Uom
                                            </label>
                                            <Field className='form-control' name="uom" value={values.capacity ? 'TON' : ''} disabled />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='required form-label'>
                                                Category
                                            </label>
                                            <SelectX
                                                defaultValue={initialValues.category}
                                                onChange={handleCategory}
                                                options={[
                                                    { label: 'Shipping', value: 'Shipping' },
                                                    { label: 'Truck', value: 'Truck' },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Harga Perolehan
                                            </label>
                                            <div className="input-group">
                                                <InputNumber
                                                    defaultValue={formatNumber(initialValues.harga_perolehan)}
                                                    min={0}
                                                    name="harga_perolehan"
                                                    className={"w-50 text-end"}
                                                    onChange={(value: any) => {
                                                        handleOnHarga(value)
                                                    }}
                                                    placeholder={'Harga input in USD'}
                                                />
                                                <Field as="select" name="currency" className="form-select" style={{ maxWidth: '100px' }} value={values.currency}>
                                                    <option value="IDR">IDR</option>
                                                    <option value="USD">USD</option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                ETA Estimasi
                                            </label>
                                            <DatepickerX2
                                                onChange={(date: any) => setFieldValue("eta_estimasi", date)}
                                                size={"lg"}
                                                value={values.eta_estimasi}
                                                format={"dd-MM-yyyy"}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Laycan Estimasi
                                            </label>
                                            <DateRangePickerX
                                                defaultValue={
                                                    values.laycan_date
                                                        ? Array.isArray(values.laycan_date)
                                                            ? values.laycan_date.map(date => new Date(date))
                                                            : JSON.parse(values.laycan_date).map((date: string) => new Date(date))
                                                        : []
                                                }
                                                onChange={(e: any) => {
                                                    if (e && e.length === 2) {
                                                        const formattedRange = [
                                                            e[0]
                                                                ? `${e[0].getFullYear()}-${String(e[0].getMonth() + 1).padStart(2, '0')}-${String(e[0].getDate()).padStart(2, '0')}`
                                                                : null,
                                                            e[1]
                                                                ? `${e[1].getFullYear()}-${String(e[1].getMonth() + 1).padStart(2, '0')}-${String(e[1].getDate()).padStart(2, '0')}`
                                                                : null
                                                        ];

                                                        setFieldValue("laycan_date", JSON.stringify(formattedRange));
                                                    } else {
                                                        setFieldValue("laycan_date", "");
                                                    }
                                                }}
                                                format={"dd-MM-yyyy"}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Komoditas
                                            </label>
                                            <Field className='form-control' name="komoditas" placeholder="input komoditas.." value={values.komoditas} onChange={(e: any) => {
                                                setFieldValue('komoditas', e.target.value.toUpperCase());
                                            }} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Vendor
                                            </label>
                                            <Field className='form-control' name="vendor" placeholder="input vendor.." value={values.vendor} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Awarding
                                            </label>
                                            <Field className='form-control' name="awarding" placeholder="input awarding.." value={values.awarding} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Port Load
                                            </label>
                                            <Field className='form-control' name="port_load" placeholder="input port load.." value={values.port_load} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='mb-6'>
                                            <label htmlFor='' className='form-label'>
                                                Port Destination
                                            </label>
                                            <Field className='form-control' name="port_destination" placeholder="input port destination.." value={values.port_destination} />
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex gap-4 mt-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Save
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={() => {
                                            setId('')
                                            setIsShowModal(false)
                                        }}
                                    >
                                        Batal
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <Modal size="xl" show={isShowModalDetail} onHide={() => setIsShowModalDetail(false)}>
                <Modal.Header>Detail Transportation <strong>{item?.name}</strong></Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-6'>
                            <ul className='list-group'>
                                <li className='list-group-item'><p><strong>Name:</strong> {item?.name}</p></li>
                                <li className='list-group-item'><p><strong>Capacity:</strong> {formatNumber(item?.capacity)}</p></li>
                                <li className='list-group-item'><p><strong>IMO:</strong> {item?.imo}</p></li>
                                <li className='list-group-item'><p><strong>Vendor:</strong> {item?.vendor}</p></li>
                                <li className='list-group-item'><p><strong>Category:</strong> {item?.category}</p></li>
                                <li className='list-group-item'><p><strong>Harga Perolehan:</strong> {formatNumber(item?.harga_perolehan)} {item?.currency}</p></li>
                                <li className='list-group-item'><p><strong>Komoditas:</strong> {item?.komoditas}</p></li>
                            </ul>
                        </div>
                        <div className='col-6'>
                            <ul className='list-group'>
                                <li className='list-group-item'><p><strong>Awarding:</strong> {item?.awarding}</p></li>
                                <li className='list-group-item'><p><strong>Uom:</strong> {item?.uom}</p></li>
                                <li className='list-group-item'><p><strong>ETA Estimasi:</strong> {item?.eta_estimasi}</p></li>
                                <li className='list-group-item'><p><strong>Laycan Estimasi:</strong> {formattedStartDate} - {formattedEndDate}</p></li>
                                <li className='list-group-item'><p><strong>Port Load:</strong> {item?.port_load}</p></li>
                                <li className='list-group-item'><p><strong>Port Destination:</strong> {item?.port_destination}</p></li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Sloc Master Data"}
                        key={formIndex}
                        readonly
                        urlIndex={getEnv("stockview") + `/transportation`}
                        columns={tablecolumns}
                        headerButtons={
                            <>
                                <Link
                                    to="/stockview/masterdata/transportation/create"
                                    className="btn btn-primary"
                                    title="Add Data"
                                >
                                    ADD Data
                                </Link>
                            </>
                        }
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { TransportationMasterDataStockviewList }
