import React, { useEffect, useState } from "react";
import TableXWrapper from "../../../../../../components/shared/TableXWrapper";
import { getEnv } from "../../../../../../../helpers/procurex";
import { Alert, Button, ButtonGroup, Col, Modal, Row, Spinner } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useGetApprovalSchema, usePostPanitiaPengadaan, useSubmitTender, useSyncVendorSAP } from "../../../hooks/proposalTenderHook";
import { toast } from "react-toastify";
import FormikSelect2 from "../../../../../../components/shared/form/FormikSelect2";
import { useAxiosGet, useAxiosPost } from "../../../../../../../hooks/useAxios";
import BottomToolbarWrapper from "../../../../../../components/shared/BottomToolbarWrapper";
import useRemoteFileAsObjectUrl from "../../../../../../../hooks/useRemoteFileAsObjectUrl";
import PreviewAttachment from "../../../../../../components/PreviewAttachment";
import { PageAlertX } from "../../../../../../components/shared/PageAlertX";
import { useIntl } from "react-intl";
import FlagRejected from "../FlagRejected";
import {darkModeClass} from '../../../../../../../helpers/utils'
import {Placeholder} from 'rsuite'
import axios from "axios";
import { ApprovalX } from "../../../../../../components/shared/ApprovalX";
import FormikPublicSelect from "../../../../../home/vendor-register/components/FormikPublicSelect";
import { toastError } from "../../../../../../../_metronic/helpers";
import {useProposalTender} from '../../core/_proposalTenderProvider'
import { useAuth } from "../../../../../auth";

interface IProps {
  proposalTender?: any;
  afterSubmit?: Function;
  afterSaveDraft?: Function;
  onUpdateLoading?: any
  isOwner?: boolean
}

interface IFormModal {
  show: boolean;
  onHide: Function;
  proposalTender: any;
  vendor?: any;
}

const FormCheckCOI = ({ proposalTender, show, onHide }: IFormModal) => {
  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide()}
        size={"lg"}
      >
        <Modal.Header closeButton={true}>
          <h4>Check Conflict Of Interest</h4>
        </Modal.Header>
        <Modal.Body className="overflow-visible">
          <table className={"table table-striped table-bordered"}>
            <thead>
            <tr>
              <th>No</th>
              <th>Vendor</th>
              <th>Status COI</th>
            </tr>
            </thead>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => onHide()}>
            <i className="fa fa-times"></i>
            Close
          </Button>
          <Button variant="primary" onClick={() => onHide()}>
            <i className="fa fa-refresh"></i>
            Refresh
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const FormCheckDURSAP = ({ proposalTender, show, onHide, vendor }: IFormModal) => {
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const validationSchema = Yup.object({
    vendor_no: Yup.object().required('The field is required'),
  })

  const [initialValues, setInitialValues] = useState<any>({
    proposal_tender_id: proposalTender?.uuid,
    vendor_no: '',
  })

  const onSubmit = async (values: any) => {
    setIsLoading(true)
    let data = {
      ...values,
      vendor_no: values?.vendor_no?.vendor_no
    }

    try {
      await axios.post(getEnv('tender') + `/dur-vendor/get-vendor-sap`, data)
    } catch (error: any) {
        setMessage(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide()}
        size={"lg"}
      >
        <Modal.Header closeButton={true}>
          <h4>Check Vendor Status di DUR SAP</h4>
        </Modal.Header>
        <Modal.Body className="overflow-visible">
          <Formik
            enableReinitialize
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({values, setFieldValue, errors}) => (
              <Form>
                <div className={"form-group mb-4"}>
                  <FormikPublicSelect
                    name='vendor_no'
                    label='Vendor'
                    optionsUrl={getEnv('vendor') + `/public/vendor`}
                    getOptionValue={(opt: any) => opt}
                    getOptionLabel={(opt) => `${opt.vendor_no} - ${opt.name}`}
                    search_property={'filter[vendor_no_name]'}
                  />
                </div>
                <div className={"form-group mb-4 text-end"}>
                  <Button variant="secondary" size={"sm"} className="me-3"
                    onClick={() => {
                      setMessage('')
                      setInitialValues({
                        proposal_tender_id: proposalTender?.uuid,
                        vendor_no: ''
                      })
                      onHide()
                    }}>
                    <i className="fa fa-times"></i>
                    Close
                  </Button>
                  <Button variant="primary" size={"sm"} type='submit' disabled={isLoading}>
                    {(isLoading) ? <Spinner className={"spinner-grow-sm me-2"} animation="border" size={"sm"} /> : <i className="fa fa-save"/>}
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <div className={"form-group"}>
            <label className={"form-label"}>Status</label>
            <textarea
              className='form-control'
              data-kt-autosize='true'
              rows={2}
              value={message}
              disabled
            ></textarea>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const FormFilter = ({ proposalTender, afterSubmit }: IProps) => {
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = usePostPanitiaPengadaan();
  const [initialValues, setInitialValues] = useState({
    sos_header: "",
    sos_item: "",
    material_group: "",
    filter_sos_dur: ""
  });
  const [formIndex, setFormIndex] = useState(0);
  const validationSchema = Yup.object().shape({
    sos_header: Yup.string().required("SOS Header harus diisi"),
    sos_item: Yup.string().required("SOS Item harus diisi"),
    material_group: Yup.string().required("Material Group harus diisi"),
    filter_sos_dur: Yup.string().required("Filter SOS DUR harus diisi")
  });
  const formik = React.useRef(null);
  const handleSubmit = (values: any) => {
    const payload = {
      ...values,
      proposal_tender_id: proposalTender.uuid
    };
    doSubmit(payload, toast, () => {
      setFormIndex(formIndex + 1);
      afterSubmit && afterSubmit();
    });
  };

  return (
    <>
      <Formik
        innerRef={formik}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        key={formIndex}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <>
            <Form>
              <Row>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label className="form-label">SOS Header Terpilih*</label>
                    <FormikSelect2
                      name="sos_header"
                      optionsUrl={`${getEnv("masterdata")}/item-sos`}
                      unique_property={"id"}
                      search_property={"name"}
                      getOptionValue={(opt: any) => opt}
                      getOptionLabel={(opt: any) =>
                        `${opt.name}`
                      }
                      isDisabled={true}
                    />
                    {errors.sos_header && touched.sos_header && (
                      <div className="fv-plugins-message-container">
                        {/* @ts-ignore*/}
                        <div className="fv-help-block">{errors.sos_header}</div>
                      </div>
                    )}
                  </div>
                  <div className="form-group mb-10">
                    <label className="form-label">SOS Item Terpilih*</label>
                    <Field
                      name="jabatan"
                      type="text"
                      className="form-control"
                      disabled
                    />
                    {errors.sos_item && touched.sos_item && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {/* @ts-ignore*/}
                          {errors.sos_item}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label className="form-label">Material Group*</label>
                    <Field
                      name="department"
                      type="text"
                      className="form-control"
                      disabled
                    />
                    {errors.material_group && touched.material_group && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {/* @ts-ignore*/}
                          {errors.material_group}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="form-group mb-10">
                    <label className="form-label">Filter SOS DUR*</label>
                    <FormikSelect2
                      name="sos_header"
                      optionsUrl={`${getEnv("masterdata")}/item-sos`}
                      unique_property={"id"}
                      search_property={"name"}
                      getOptionValue={(opt: any) => opt}
                      getOptionLabel={(opt: any) =>
                        `${opt.name}`
                      }
                      isDisabled={true}
                    />
                    {errors.filter_sos_dur && touched.filter_sos_dur && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {/* @ts-ignore*/}
                          {errors.filter_sos_dur}
                        </div>
                      </div>
                    )}
                  </div>

                </Col>
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

const StatusBadgeVendor = ({ status }: any) => {
  const renderStatus = () => {
    switch (status) {
      case "PRA-QUALIFICATION":
        return (<span className={"badge badge-success"}>{status}</span>);
      case "OPEN":
        return (<span className={"badge badge-info"}>{status}</span>);
      case "INVITED":
        return (<span className={"badge badge-warning"}>{status}</span>);
      default:
        return (<span className={"badge badge-primary"}>{status}</span>);
    }
  };
  return (
    <>
      {renderStatus()}
    </>
  );
};

const DURPage = ({ afterSubmit, onUpdateLoading, afterSaveDraft, isOwner }: IProps) => {
  const { currentUser } = useAuth();
  const {proposalTender} = useProposalTender()
  const [urlIndex, setUrlIndex] = useState("");
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useSyncVendorSAP();
  const [{
    doSubmit: doSubmitPost,
    isLoading: isLoadingPost,
    data: dataPost
  }] = useAxiosPost(`${getEnv("tender")}/dur-vendor/${proposalTender?.uuid}}`);
  const [selectedRows, setSelectedRows] = useState([] as any);
  const [formIndex, setFormIndex] = useState(0);
  const [isModalInvitationShow, setIsModalInvitationShow] = useState(false);
  const [showCheckCOI, setShowCheckCOI] = useState(false);
  const [showCheckDURSAP, setShowCheckDURSAP] = useState(false);
  const { objectUrl, isLoading: isLoadingObjectUrl, setSourceUrl, resetObjectUrl } = useRemoteFileAsObjectUrl();
  const [durNote, setDurNote] = useState(proposalTender?.dur_note);
  const [currentShowID, setCurrentShowID] = useState([] as any);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isShowPrint, setIsShowPrint] = useState(false);
  const intl = useIntl();
  const [buttonSaveEnabled, setButtonSaveEnabled] = useState(true)
  const [replaceIsOwner, setReplaceIsOwner] = useState(isOwner)

  const handleOnCheck = (e: any, data: any) => {
    if (e.target.checked) {
      let currentSelectedRows = [...selectedRows];
      // @ts-ignore
      currentSelectedRows.push(data.uuid);
      setSelectedRows(currentSelectedRows);
    } else {
      let currentSelectedRows = [...selectedRows];
      // @ts-ignore
      const index = currentSelectedRows.indexOf(data.uuid);
      if (index > -1) {
        currentSelectedRows.splice(index, 1);
        setSelectedRows(currentSelectedRows);
      }
    }
  };

  const handleOnCheckTable = (data: any) => {
    let currentSelectedRows = [...selectedRows];
    data?.map((item: any) => {
      // @ts-ignore
      if (!selectedRows.includes(item?.original?.uuid)) {
        // @ts-ignore
        currentSelectedRows.push(item?.original?.uuid);
      }
    });
    setSelectedRows(currentSelectedRows);
  };

  const getStatusDUR = (status: string) => {
    const isInvitedStatus = [
      "INVITED",
      "REJECT-INVITATION",
      "REGISTRATION",
      "REGISTRATION-QUOTATION",
      "REJECT-QUOTATION",
      "NEGOTIATION",
      "NEGOTIATION_PROCESS",
      "WINNER"
    ];
    if (isInvitedStatus.includes(status)) {
      return "INVITED";
    }
    return "OPEN";
  };

  const getStatusOpen = (status: string) => {
    const isPassed = [
      "NO-DATA",
      "PASSED"
    ];
    if (isPassed.includes(status)) {
      return "OPEN";
    }
    return "FAILED PQ";
  };

  const tableColumns = [
    {
      Header: ({ row }: any) => {
        return (
          <div className={"px-0"} style={{
            left: "0"
          }}>
            <input
              className={"w-20px h-20px"}
              style={{ borderColor: `#fff`, borderRadius: "4px" }}
              onChange={handleOnCheckAll}
              checked={isCheckAll}
              disabled={!buttonSaveEnabled}
              type={"checkbox"}
            />
          </div>
        );
      },
      accessor: "selection",
      width: 50,
      searchable: false,
      disableResizing: true,
      sortable: false,
      disableSortBy: true,
      className: "text-center",
      Cell: ({ row }: any) => {
        const data = row.original;
        const id = data?.uuid;
        const isChecked = () => {
          // @ts-ignore
          return selectedRows.includes(id)
        }
        return (
          <div className={"px-0"} style={{
            left: "0"
          }}>
            <input
              className={"form-check-input border-primary"}
              checked={isChecked()}
              style={{ borderColor: `#fff`, borderRadius: "4px" }}
              onChange={(e) => {
                handleOnCheck(e, row?.original);
              }}
              disabled={!buttonSaveEnabled}
              type={"checkbox"}
            />
          </div>
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Status DUR" }),
      accessor: "status_tender2",
      width: 100,
      className: "text-center",
      Cell: ({ row }: any) => {
        return (
          <StatusBadgeVendor status={getStatusDUR(row.original.status_tender)} />
        );
      }
    },
    {
      Header: intl.formatMessage({ id: "Status" }),
      accessor: "status_tender",
      width: 200,
      className: "text-center",
      Cell: ({ row }: any) => {
        return (
          <StatusBadgeVendor status={row.original.status_tender} />
        );
      }
    },
    { Header: intl.formatMessage({ id: "ID Vendor" }), accessor: "vendor_id", width: 100, className: "text-center" },
    { Header: intl.formatMessage({ id: "NO SAP" }), accessor: "vendor_no_sap", width: 100, className: "text-center" },
    { Header: intl.formatMessage({ id: "Nama Vendor" }), accessor: "vendor.name", width: 200, className: "text-start" },
    { Header: intl.formatMessage({ id: "Total RFQ" }), accessor: "total_rfq", width: 100, className: "text-center" },
    {
      Header: intl.formatMessage({ id: "Badan Usaha" }),
      accessor: "vendor.company_type_name",
      width: 100,
      className: "text-center",
      Cell: ({ row }: any) => {
        const data = JSON.parse(row?.original?.vendor?.company_type);
        return (
          <span>{data?.name}</span>
        );        
      }
    },
    // { Header: intl.formatMessage({ id:  "Badan Usaha" }), accessor: "department", width: 150, className: "text-center" },
    { Header: intl.formatMessage({ id: "Domisili" }), 
      accessor: "domisili", 
      width: 175, 
      className: "text-center",
      Cell: ({ row }: any) => {
        const data = JSON.parse(row?.original?.vendor?.district);
        return (
          <span>{data?.name}</span>
        );        
      }
    },
    { Header: intl.formatMessage({ id: "Nilai VPR" }), accessor: "nilai_vpr", width: 100, className: "text-center" },
    { Header: intl.formatMessage({ id: "COI" }), accessor: "coi", width: 100, className: "text-center" },
    // { Header: intl.formatMessage({ id: "Status" }), accessor: "status", width: 100, className: "text-center" },
    {
      Header: intl.formatMessage({ id: "PO Outstanding" }),
      accessor: "po_outstanding",
      width: 200,
      className: "text-center",
      Cell: ({ row }: any) => {
        const data = row?.original?.vendor_data_sync_sap?.outspo;
        return (
          <span>{data}</span>
        );       
      }
    },
    {
      Header: intl.formatMessage({ id: "Usulan User" }),
      accessor: "usulan_user",
      width: 200,
      className: "text-center"
    },
    {
      Header: intl.formatMessage({ id: "Dokumen Exp" }),
      accessor: "document_exp",
      width: 200,
      className: "text-center"
    }
  ];

  const isButtonSaveEnabled = () => {
    const allowedStatus = ["Draft", "DUR"];
    const allowedStatusDetail = ["Draft", "Submit for Approval DUR", "Rejected"];
    return allowedStatus.includes(proposalTender?.status) && allowedStatusDetail.includes(proposalTender?.status_detail);
  };

  useEffect(() => {
    setFormIndex(formIndex + 1);
  }, [data, dataPost]);

  const handleSave = async (e: any) => {
    e.preventDefault();
    // console.log(selectedRows)
    doSubmitPost({
        proposal_tender_id: proposalTender?.uuid,
        vendors: selectedRows,
        note: durNote
      }, `${getEnv("tender")}/dur-vendor/invite`,
      toast
    ).then((res: any) => {
      setIsModalInvitationShow(false);
      checkIsCanSubmitForApproval();
      afterSubmit && afterSubmit();
    });
  };

  const handleAfterLoadData = (data: any) => {
    let currentSelectedRows = [...selectedRows];
    let _currentShowID = [] as any;
    data?.data?.map((item: any) => {
      // @ts-ignore
      if (item.status_tender === "INVITED" && !selectedRows.includes(item.uuid)) {
        // @ts-ignore
        currentSelectedRows.push(item.uuid);
      }
      _currentShowID.push(item.uuid);
    });
    if(!isMounted) setSelectedRows(currentSelectedRows);
    setCurrentShowID(_currentShowID);
  };

  const handleOnCheckAll = async (e: any) => {
    if (e.target.checked) {
      currentShowID?.map((item: any) => {
        const tmp = selectedRows;
        // @ts-ignore
        if (!selectedRows.includes(item)) {
          // @ts-ignore
          tmp.push(item);
        }
        setSelectedRows(tmp);
      });
    } else {
      setSelectedRows([]);
    }
    setIsCheckAll(e.target.checked);
    setFormIndex(formIndex + 1);
    setIsMounted(true);
  }

  const isShowFlagRejected = () => {
    const proposalTenderRejectedStatus = ["DUR", "Draft"];
    const proposalTenderRejectedStatusDetail = ["Rejected"];
    return proposalTenderRejectedStatus.includes(proposalTender?.status) && proposalTenderRejectedStatusDetail.includes(proposalTender?.status_detail);
  }

  useEffect(() => {
    generateUrlIndex();
  }, []);

  const generateUrlIndex = () => {
    const proposalTenderOnlyShowOpenInvited = ["DUR", "Draft"];
    if (proposalTenderOnlyShowOpenInvited.includes(proposalTender?.status)) {
      if (proposalTender?.praqualification === true)
        setUrlIndex(getEnv("tender") + `/dur-vendor?filter[status_tender]=OPEN,INVITED&filter[status_pra_qualification]=NO-DATA,PASSED,NOT-EVALUATE`);
      else
        setUrlIndex(getEnv("tender") + `/dur-vendor?sort=status_tender&filter[status_tender]=OPEN,INVITED`);
    } else {
      setUrlIndex(getEnv("tender") + `/dur-vendor?filter[status_tender]=INVITED,REGISTRATION-QUOTATION,REJECT-INVITATION,REGISTRATION,REJECT-QUOTATION,NEGOTIATION,NEGOTIATION_PROCESS,WINNER`);
    }
  };

  const checkIsCanSubmitForApproval =  async() => {
    const shouldCheckOnStatus = [
      "DUR",
      "Draft"
    ];
    const shouldCheckOnStatusDetail = [
      "Draft",
      "Rejected"
    ];

    axios
      .get(getEnv("tender") + `/tender-attachment?filter[proposal_tender_id]=${proposalTender?.id}`)
      .then((res) => {
        let count = 0
        res.data.data.forEach((vendor:any) => {
          if(vendor?.is_skv && vendor?.media_type?.name !== "PR_TENDER_HPS") count++
        });
        console.log('count', count)
        if(count === 0) {
          setIsCanSubmitForApproval({
            state: false,
            message: "Centang SKV minimal 1 selain HPS"
          })
          return
        }
      })

    axios
      .get(getEnv("tender") + `/tender-attachment?filter[proposal_tender_id]=${proposalTender?.id}`)
      .then((res) => {
        let count = 0
        res.data.data.forEach((vendor:any) => {
          if(vendor?.status_tender === "INVITED") count++
        });
      })

    if(proposalTender?.praqualification && proposalTender?.status === "Draft") return;
    if(shouldCheckOnStatus.includes(proposalTender?.status) && shouldCheckOnStatusDetail.includes(proposalTender?.status_detail)){
      const newUrl = getEnv('tender') + `/dur/submission/${proposalTender?.uuid}`
      doSubmitGet({},newUrl,undefined).then((res:any)=>{
        setIsCanSubmitForApproval({
          state:true,
          message: ''
        })
      }).catch((err:any)=>{
        setIsCanSubmitForApproval({
          state:false,
          message: err?.response?.data?.meta?.message
        })
      })
    }
  }

  useEffect(() => {
    if (proposalTender) {
      checkIsCanSubmitForApproval();
      setButtonSaveEnabled(isButtonSaveEnabled())
      
      if (proposalTender?.is_on_approval && 
        proposalTender?.status_detail === 'Submit for Approval DUR' && 
        currentUser?.username === (proposalTender?.current_approval_user[0] || '')
      ) {
        setReplaceIsOwner(true)
      } else {
        setReplaceIsOwner(isOwner)
      }
    }
  }, [proposalTender]);

  const [{ doSubmit: doSubmitGet, isLoading: isLoadingGet }] = useAxiosGet(
    getEnv("tender") + "/negotiation"
  )
  const [{
    doGetDetail: doGetDetailApprovalSchema,
    isLoading: isLoadingSchema,
    data: dataSchema
  }] = useGetApprovalSchema()
  const [isCanSubmitForApproval,setIsCanSubmitForApproval] = useState({
    state: false,
    message: ''
  })
  const [{
    doSubmit: doSubmitTender,
    isLoading: isLoadingSubmitTender,
    isError: isErrorSubmitTender,
    data: dataSubmitTender,
    isSuccess: isSuccessSubmitTender
  }] = useSubmitTender(proposalTender?.uuid)
  const [approvalSchema, setApprovalSchema] = React.useState([]);
  const [isShowApprovalSchema, setIsShowApprovalSchema] = React.useState(false);

  const handleOnSubmitTender = async () => {
    doGetDetailApprovalSchema(proposalTender?.uuid, toast).then((res: any) => {
      setIsShowApprovalSchema(true);
      setApprovalSchema(res.details);
    }).catch((error) => {
      toast.error(error);
    }).finally(() => {
      onUpdateLoading && onUpdateLoading(false);
    });
  }

  const handleOnSubmitApproval = async (values: any) => {
    const payload = {
      approvers: values.approval.map((item: any) => {
        return {
          step: item.step,
          jabatan: item.jabatan,
          type: "APPROVAL",
          user_id: item?.extra_info?.user?.user_id,
          extra_info: item?.extra_info
        };
      })
    };
    doSubmitTender(payload, toast, () => {
    }).then((data: any) => {
      setIsShowApprovalSchema(false);
      setButtonSaveEnabled(false)
      afterSubmit && afterSubmit();
      checkIsCanSubmitForApproval()
    }).catch(() => {
    });
  };

  useEffect(() => {
    if(proposalTender) generateUrlIndex();
  }, [proposalTender])

  if(!urlIndex || !proposalTender) {
    return <Placeholder.Grid columns={10} rows={5} />
  }

  return (
    <>
      <ApprovalX
        show={isShowApprovalSchema}
        schema={approvalSchema}
        onHide={() => {
          setIsShowApprovalSchema(false);
        }}
        onSubmit={(data: any) => {
          handleOnSubmitApproval(data);
        }}
        isLoading={isLoadingSubmitTender}
        source={"TENDER"}
        object_id={proposalTender?.id}
        company_id={proposalTender?.company?.id}
        code={proposalTender?.routing_approval_config_code}
        readOnly={proposalTender?.is_on_approval}
      />

      {isShowFlagRejected() && proposalTender && (
        <FlagRejected proposalTender={proposalTender} type={"DUR"} />
      )}

      <PageAlertX title={"Petunjuk DUR"} variant={"info"} html={
        <>
          <ol>
            <li className={"text-dark"}>Klik tombol SYNC SAP terlebih dahulu untuk mengambil DUR dari SAP.</li>
            <li className={"text-dark"}>Pastikan status vendor INVITED agar terundang di tender tsb, Status OPEN tidak
              akan diundang.
            </li>
            <li className={"text-dark"}>Untuk mengubah status, centang lalu klik tombol Simpan DUR.</li>
          </ol>
        </>
      } />
      {isCanSubmitForApproval?.message && (
        <div className={"alert alert-warning text-danger"}>
          <i className={"fa fa-warning text-danger me-2"}></i> {isCanSubmitForApproval?.message}
        </div>
      )}
      <div className={"p-0"}>
        <div className={"d-flex justify-content-between"}>
          <h3>{intl.formatMessage({ id: "Daftar Usulan Rekanan" })}</h3>
        </div>
        <hr />
        <FormFilter proposalTender={proposalTender} afterSubmit={() => {
          setFormIndex(formIndex + 1);
        }} />
        <hr />
        <TableXWrapper
          key={formIndex}
          readonly={true}
          title={"DUR"}
          urlIndex={urlIndex}
          columns={tableColumns}
          defaultFilter={`filter[proposal_tender_id]=${proposalTender?.id}`}
          isForceCreateButton={true}
          disablePaddingCard={true}
          hideSelection={true}
          hideShowPagination={false}
          hideExportAs={false}
          onAfterLoadData={(data: any) => {
            handleAfterLoadData(data);
          }}
          headerButtons={
            <>
              <ButtonGroup>
                <Button size={"sm"} variant={"secondary"} disabled
                        onClick={() => setShowCheckCOI(true)}>
                  {isLoading ? <i className={"fa fa-spin fa-spinner"}></i> : <i className={"fa fa-check-circle"}></i>}
                  Check COI
                </Button>
                <Button size={"sm"} disabled={isLoading || !buttonSaveEnabled} variant={"secondary"}
                        onClick={() => doSubmit(proposalTender?.uuid, toast)}>
                  {isLoading ? <i className={"fa fa-spin fa-spinner"}></i> : <i className={"fa fa-refresh"}></i>}
                  Sync SAP
                </Button>
                <Button size={"sm"} variant={"secondary"} onClick={() => {
                  setSourceUrl(`${getEnv("tender")}/dur/generate-berita-acara/${proposalTender.uuid}`);
                  setIsShowPrint(true)
                }}>
                  {/*{isLoading || isLoadingObjectUrl ? <i className={"fa fa-spin fa-spinner"}></i> :*/}
                    <i className={"fa fa-print"}></i>
                  Print
                </Button>
                <Button size={"sm"} variant={"secondary"}
                        // disabled={!buttonSaveEnabled}
                        disabled={true}
                        onClick={() => setShowCheckDURSAP(true)}>
                  <i className="fa fa-user-check"/>
                  Check Vendor
                </Button>
              </ButtonGroup>
            </>
          }


          disableUrlChanged={true}
        />
        <div className={"form-group"}>
          <label className={"form-label"}>{intl.formatMessage({ id: "Catatan DUR" })}</label>
          <textarea className={"form-control"} onChange={(e: any) => setDurNote(e.target.value)}
                    defaultValue={durNote}></textarea>
        </div>

        <BottomToolbarWrapper isOwner={replaceIsOwner} children={
          <>
            <button className={"btn btn-info"} type={"button"} disabled={!buttonSaveEnabled || isLoading}
                    onClick={handleSave}>
              {(isLoadingPost || isLoading) ? <Spinner className={"spinner-grow-sm me-2"} animation="border" size={"sm"} /> : <i className={"fa fa-save"}></i>}
              {intl.formatMessage({ id: "Simpan DUR" })}
            </button>

            {buttonSaveEnabled &&
              <button
                className={"btn btn-primary ms-3"}
                type={"button"}
                onClick={handleOnSubmitTender}
                disabled={isLoadingSubmitTender || !isCanSubmitForApproval?.state}

              >
                {isLoading && <Spinner className={"spinner-grow-sm me-2"} animation="border" size="sm" />}
                {!isLoading && <i className={"fa fa-paper-plane me-2"}></i> }
                {intl.formatMessage({id:'Submit for Approval'})}
              </button>
            }
          </>
        } />

        <FormCheckCOI show={showCheckCOI} onHide={() => setShowCheckCOI(false)} proposalTender={proposalTender} />

        <FormCheckDURSAP show={showCheckDURSAP} onHide={() => setShowCheckDURSAP(false)} proposalTender={proposalTender}
                         vendor={selectedRows[0]} />

        {/*<PreviewAttachment previewExt={objectUrl} onHide={() => resetObjectUrl()} />*/}
        <Modal
          show={isShowPrint}
          onHide={() => {
            setIsShowPrint(false)
          }}
          size={"xl"}
          backdrop="static"
          // centered
        >
          <Modal.Header closeButton={true} className="">
            <h4 className={darkModeClass()}>Preview DUR</h4>
          </Modal.Header>
          <Modal.Body className="border overflow-visible" key={JSON.stringify(objectUrl)}>
            {isLoadingObjectUrl && (
              <Placeholder.Graph active />
            )}
            {!isLoadingObjectUrl && (
                <>
                  {(typeof objectUrl === "string") && (
                    <>
                      <object width="100%" style={{
                        height: "700px"
                      }} data={objectUrl} type="application/pdf">
                        {" "}
                      </object>
                    </>
                  )}
                </>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default DURPage;