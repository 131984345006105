import React, { useEffect, useState } from "react";
import { useAxiosGet, useAxiosPost } from "../../../../../../hooks/useAxios";
import {getEnv, parseNumberToCurrency, parseNumberToFloat} from '../../../../../../helpers/procurex'
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {Badge, InputNumber, Panel, Stack} from 'rsuite'
import NoteAttachmentEvaluation from "./NoteAttachmentEvaluation";
import PreviewAttachment from "../../../../../components/PreviewAttachment";
import { Spinner } from "react-bootstrap";
import {EvaluasiKlarifikasiAdministrasiTeknisList} from './EvaluasiKlarifikasiAdministrasiTeknisList'
import CurrencyInput from "react-currency-input-field";
import axios from 'axios'

const EvaluasiKomersil = ({ proposalTender, quotation, onChange, onChangeEvaluationData, isOwner }: any) => {
  const [syarats, setSyarats] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isUncheckAll, setIsUncheckAll] = useState(false);
  const [renderKey, setRenderKey] = useState(0);
  const [note, setNote] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [deletedAttachment, setDeletedAttachment] = useState([]);
  const [previewAttachment, setPreviewAttachment] = useState(null);
  const [{ doSubmit, isLoading, isError, data, isSuccess }] = useAxiosGet(
    getEnv("tender") + `/evaluation/item/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`
  );
  const [durVendor, setDurVendor] = useState(null as any);
  const [dataEvaluation, setDataEvaluation] = useState<any>([]);
  const intl = useIntl();
  const [debounceTimer, setDebounceTimer] = useState<any>(null);
  const [{ doSubmit: doSubmitSave, isLoading: isLoadingSave }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  );
  const [statusEvaluationCommercial, setStatusEvaluationCommercial] = useState("NOT-EVALUATE");
  const [scorePaketSistemNilai, setScorePaketSistemNilai] = useState(0);
  const [isExpand, setIsExpand] = useState(false)
  const getSyarats = () => {
    doSubmit({},
      getEnv("tender") + `/evaluation/commercial/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`,
      undefined, () => {
      }
    ).then((res: any) => {
      setSyarats(res.data);
    }).catch((err: any) => {
    });
  };

  const getDurVendor = async () => {
    const url = getEnv("tender") + `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&filter[vendor_id]=${quotation?.vendor_id}`;
    doSubmit({}, url, undefined, () => {}).then((res: any) => {
      setDurVendor(res.data[0]);
    }).catch((err: any) => {});
  };

  const getSelectedVendor = () => {
    axios
      .get(getEnv("tender") + `/dur-vendor?filter[proposal_tender_id]=${proposalTender?.id}&perPage=1000`)
      .then((res) => {
        const _selectedVendor = res.data.data?.find((vendor: any) => {
          return (vendor?.vendor_id).toString() === (quotation?.vendor_id).toString();
        });
        setSelectedVendor(_selectedVendor);
        setStatusEvaluationCommercial(_selectedVendor?.status_evaluation_commercial);
        setScorePaketSistemNilai(_selectedVendor?.score);
        console.log(_selectedVendor?.status_evaluation_commercial);
      })
  };

  const getItems = () => {
    doSubmit({}, undefined, undefined, () => {
    }).then((res: any) => {
      const _items = res.data;
      if (isPaketSistemGugur()) {
        _items?.forEach((item: any) => {
          // @ts-ignore
          item.commercial_status = selectedVendor?.status_evaluation_commercial === "PASSED";
        });
      }
      setDataItems(_items);
    }).catch((err: any) => {
      toast.error("Get Evaluasi Komersil: " + err?.response?.data?.meta?.message);
    });
  };

  const updateSyarat = (index: number, state: string) => {
    const newSyarats = syarats.map((syarat: any, i: number) => {
      if (i === index) {
        syarat.status = state;
      }
      return syarat;
    });
    // @ts-ignore
    setSyarats(newSyarats);
  };

  const handleOnScoreChange = (score: number) => {
    setScorePaketSistemNilai(score);
  };

  const getDataEvaluationItem = (index: number) => {
    return dataEvaluation
      ?.find((item: any) => item?.quotation_item_id === dataItems?.[index]?.uuid)
      ?.evaluation;
  }

  const saveSyarat = () => {
    if (proposalTender?.order_placement === "Paket") {
      submitSyaratIfPaket();
    } else if (proposalTender?.order_placement === "Itemize") {
      submitSyaratIfItemize();
    }
  };

  const submitSyaratIfPaket = () => {
    // @ts-ignore
    const requirements = [];
    let isAllPassed = true;
    syarats.forEach((syarat: any) => {
      if(syarat?.status !== 'NOT-EVALUATE') {
        requirements.push({
          id: syarat.uuid,
          evaluation: syarat.status === "PASSED",
          description: ""
        });
      }
      if (syarat.status !== "PASSED") isAllPassed = false;
    });

    // @ts-ignore
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      // @ts-ignore
      requirements: requirements
    };

    const isNeedEvaluateCommercial = proposalTender?.metode_evaluasi === "Sistem Gugur";

    if(requirements.length !== 0) {
      doSubmitSave(payload, getEnv("tender") + `/evaluation/commercial/syarat/${proposalTender?.uuid}`, !isNeedEvaluateCommercial ? toast : undefined, () => {
      }).then((res: any) => {
        const evaluateVendorURL = getEnv("tender") + `/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`;
        doSubmitSave({}, evaluateVendorURL, undefined, () => {}).then((res: any) => {
          onChange && onChange();
          getDurVendor();
        }).catch((err: any) => {});
      //   // submit commercial jika sistem gugur
      //   if (isNeedEvaluateCommercial) {
      //     const payloadCommercialEvaluation = {
      //       proposal_tender_vendor_id: quotation?.vendor?.uuid,
      //       evaluation: isAllPassed
      //     };
      //     doSubmitSave(payloadCommercialEvaluation, getEnv("tender") + `/evaluation/commercial/item/${proposalTender?.uuid}`, toast, () => {
      //     }).then((res: any) => {
      //
      //     });
      //   } else {
      //     onChange && onChange();
      //   }
      });
    }
  };

  const submitSyaratIfItemize = () => {
    // @ts-ignore
    const requirements = [];
    syarats.forEach((syarat: any) => {
      if(syarat?.status !== 'NOT-EVALUATE') {
        requirements.push({
          id: syarat.uuid,
          evaluation: syarat.status === "PASSED",
          description: ""
        });
      }
    });

    // @ts-ignore
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      // @ts-ignore
      requirements: requirements
    };
    if(requirements.length !== 0) {
      doSubmitSave(payload, getEnv("tender") + `/evaluation/commercial/syarat/${proposalTender?.uuid}`, toast, () => {
      }).then((res: any) => {
        const evaluateVendorURL = getEnv("tender") + `/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`;
        doSubmitSave({}, evaluateVendorURL, undefined, () => {}).then((res: any) => {
          onChange && onChange();
          getDurVendor();
        }).catch((err: any) => {});
      });
    };
  };

  const submitEvaluationItemize = (item: any, state: boolean) => {
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      quotation_item_id: item?.uuid,
      evaluation: state,
      note: "-"
    };
    doSubmitSave(payload, getEnv("tender") + `/evaluation/commercial/item/${proposalTender?.uuid}`, toast, () => {
      dataItems.forEach((item2: any) => {
        if (item2?.uuid === item?.uuid) {
          item.commercial_status = state ? "PASSED" : "FAILED";
        }
      });
    }).then((res: any) => {
      onChange && onChange();
      getDurVendor();
    });
  };

  const isItemize = () => {
    return proposalTender?.order_placement === "Itemize";
  };

  const isItemizeSistemGugur = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const handleOnSubmitEvaluasiKomersil = async() => {
    Swal.fire({
      title: `${intl.formatMessage({id:"Apakah Anda yakin"})}?`,
      html: `${intl.formatMessage({id:"Anda akan mensubmit Evaluasi Komersil"})}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ya, Submit`,
      cancelButtonText: intl.formatMessage({id:"Cancel"}),
    }).then((result) => {
      if (result.isConfirmed) {
        //region save evaluation
        dataEvaluation.forEach((item: any,index:number) => {
          doSubmitSave(item, getEnv("tender") + `/evaluation/commercial/item/${proposalTender?.uuid}`, undefined).then((res:any)=>{
            if(index === dataEvaluation.length-1){
              const evaluateVendorURL = getEnv("tender") + `/evaluation/vendor/v2/${proposalTender?.uuid}/${quotation?.vendor?.uuid}`;
              doSubmitSave({}, evaluateVendorURL, toast, () => {}).then((res: any) => {
                onChange && onChange();
                getDurVendor();
                getItems();
                setIsExpand(!isExpand)
                setRenderKey(renderKey+1)
              }).catch((err: any) => {});
            }
          });
        });
        //end region

        //region save syarat
        saveSyarat();

        //save note
        if (note) {
          doSubmitSave({
            note: note || "-",
            type: "Evaluasi Komersil"
          }, getEnv("tender") + `/evaluation/notes/${quotation?.vendor?.uuid}`, undefined);

        }

        //save attachment
        // @ts-ignore
        if (attachment && attachment.length > 0) {
          const payloadAttachment = {
            // @ts-ignore
            description: attachment[0]?.media_type?.file_name,
            type: "Evaluasi Komersil",
            // @ts-ignore
            media_type: attachment[0]?.media_type,
            // @ts-ignore
            media_url: attachment[0]?.media_url
          }
          doSubmitSave(payloadAttachment, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}`, undefined);
        }

        //delete attachment
        // @ts-ignore
        if (deletedAttachment && deletedAttachment.length > 0) {
          deletedAttachment.forEach((item: any) => {
            doSubmitSave({
              _method: "DELETE"
            }, getEnv("tender") + `/evaluation/attachment/${quotation?.vendor?.uuid}/${item}`, undefined);
          })
        }
      }
    })
  }

  const isFormEnabled = () => {
    const allowedStatus = [
      "Bid Opening",
      "Evaluasi Administrasi",
      "Evaluasi Teknikal",
      "Evaluasi Komersil"
    ];
    return allowedStatus.includes(proposalTender?.status) && !isLoadingSave;
  };

  const isPaketSistemGugur = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Gugur";
  };

  const isPaketSistemNilai = () => {
    return proposalTender?.order_placement === "Paket" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };
  const isItemizeSistemNilai = () => {
    return proposalTender?.order_placement === "Itemize" &&
      proposalTender?.metode_evaluasi === "Sistem Nilai";
  };

  const submitIfPaketSistemGugur = (state: boolean) => {
    setStatusEvaluationCommercial(state ? "PASSED" : "FAILED");
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      evaluation: state
    }
    let tmp = dataEvaluation
    tmp = tmp.filter((item: any) => item.proposal_tender_vendor_id !== payload.proposal_tender_vendor_id)
    tmp.push(payload)
    setDataEvaluation(tmp)

    // doSubmitSave({
    //
    // }, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`, toast)
    //   .then((res: any) => {
    //     onChange && onChange();
    //   });
  };

  const submitIfItemizeSistemGugur = (item: any, state: boolean, notes: string, index: any) => {
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      quotation_item_id: item?.uuid,
      evaluation: state,
      note: notes
    };
    let tmp = dataEvaluation;
    tmp = tmp.filter((item: any) => item.quotation_item_id !== payload.quotation_item_id)
    tmp.push(payload)
    if (index === "all") {
      return payload
    } else {
      setDataEvaluation(tmp)
    }

    // doSubmitSave(payload, getEnv("tender") + `/evaluation/technical/item/${proposalTender?.uuid}`,
    //   toast, () => {
    //   }).then((res: any) => {
    //   dataItems.forEach((item2: any) => {
    //     if (item2?.uuid === item?.uuid) {
    //       item.technical_status = state ? "PASSED" : "FAILED";
    //     }
    //   });
    //   onChange && onChange();
    // });
  };

  const handleOnCheck = (index: any, state: boolean) => {
    let tmpEval = [];
    const newItems = dataItems.map((item: any, i: number) => {
      if (isItemizeSistemGugur() && item.quote == "NO-QUOTE") return item;
      if (i === index) {
        item.commercial_status = state;
        if (isPaketSistemGugur()) submitIfPaketSistemGugur(state);
        if (isItemizeSistemGugur()) submitIfItemizeSistemGugur(item, state, "-", index);
      }
      if (index === "all") {
        item.commercial_status = state;
        if (isItemizeSistemGugur()) {
          const submit = submitIfItemizeSistemGugur(item, state, "-", index);
          tmpEval.push(submit)
        }
      }
      return item;
    });
    // @ts-ignore
    setDataItems(newItems);
    if (index === "all") setDataEvaluation(tmpEval);
  };

  const handleCheckAll = (e: any) => {
    if (e.target.checked) {
      setIsCheckAll(true);
      setIsUncheckAll(false);
      handleOnCheck("all", true);
    } else {
      setIsCheckAll(false);
      setIsUncheckAll(false);
      setDataEvaluation([])
    }
  };

  const handleUncheckAll = (e: any) => {
    if (e.target.checked) {
      setIsCheckAll(false);
      setIsUncheckAll(true);
      handleOnCheck("all", false);
    } else {
      setIsCheckAll(false);
      setIsUncheckAll(false);
      setDataEvaluation([])
    }
  };

  const debounce = (func: any, delay: number) => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const timer = setTimeout(func, delay);
    setDebounceTimer(timer);
  };

  const handleOnChangeScoreItemizeSistemNilai = (item: any, score: any) => {
    const payload = {
      proposal_tender_vendor_id: quotation?.vendor?.uuid,
      quotation_item_id: item?.uuid,
      score: score,
      note: "-"
    };

    let tmp = dataEvaluation
    tmp = tmp.filter((item: any) => item.quotation_item_id !== payload.quotation_item_id)
    tmp.push(payload)
    setDataEvaluation(tmp)


  };

  useEffect(() => {
    getItems();
    getSyarats();
    getDurVendor();
    getSelectedVendor();
  }, []);

  const getTotalValueFromVendorQuotation = () => {
    let total = '0';
    let _total = 0;

    // backlog: currency not found
    let curr = dataItems[0]?.currency?.code ?? 'IDR';

    dataItems.forEach((item: any) => {
      _total += parseFloat(item.total_value);
    });

    total = _total.toLocaleString('id-ID', {
              style: 'currency',
              currency: curr
            });

    return total;
  }

  // useEffect(() => {
  //   if (syarats?.length > 0 && renderKey > 0) saveSyarat();
  //   if (syarats?.length > 0) setRenderKey(renderKey + 1);
  // }, [syarats]);

  const TableItemService = ({proposalTender, service, quotation, item, index,}: any) => {
    let serviceLinesCount         = item?.service_lines_quotation?.length === 0 ? 0 : item?.service_lines_quotation?.length;
    let totalRowSpanPerItem       = serviceLinesCount + 1;

    let totalServiceLinesAllItem  = dataItems.reduce((total: any, item: { service_lines_quotation: string | any[]; }) => {
      return total + ((item?.service_lines_quotation?.length === 0) ? 0 : item?.service_lines_quotation?.length);
    }, 0);

    let totalRowSpan              = totalServiceLinesAllItem + dataItems.length;


    return (
      <>
        {/* Start No */}
        <td className={"border px-2 text-center"} rowSpan={totalRowSpanPerItem} 
          style={{ position: "sticky", left: 0, zIndex: 1, verticalAlign: "middle", backgroundColor: "#fff" }}
        >{index + 1}</td>
        {/* End No */}

        {/* Start Short Text */}
        <td className={"border"}
            style={{ position: "sticky",left: 30,zIndex: 1,backgroundColor: "#fff" }}>{item?.short_text}
        </td>
        {/* End Short Text */}

        {/* Start Specification */}
        <td className={"border"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`}} rowSpan={totalRowSpanPerItem}>
          <div style={{
            maxHeight: `${40 * item.serviceLinesCount}px`,
            width: "250px",
            overflow: "auto"
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.spesification}
              rows={(3 * serviceLinesCount) + 1}
              disabled={true}
              maxLength={250}>
            </textarea>
          </div>
        </td>
        {/* End Specification */}

        {/* Start Comply or Deviate */}
        <td className={"border text-center text-nowrap"} rowSpan={totalRowSpanPerItem} style={{
          verticalAlign: "middle",
        }}>
          <strong>{item?.quote}</strong>
        </td>
        {/* End Comply or Deviate */}

        {/* Start Curr */}
        <td className={"border"}>{item?.currency?.code}</td>
        {/* End Curr */}

        {/* Start Uom */}
        <td className={"border"}>{item?.uom?.uom}</td>
        {/* End Uom */}

        {/* Start Per */}
        <td className={"border text-center text-nowrap"} rowSpan={totalRowSpanPerItem} style={{
          verticalAlign: "middle",
        }}>{item?.per}</td>
        {/* End Per */}

        {/* Start Deskripsi Addcost */}
        <td className={"text-center border align-start"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle"}} rowSpan={totalRowSpanPerItem}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.additional_cost_description}
              rows={(2 * serviceLinesCount)}
              disabled={true}
              maxLength={250}>
            </textarea>
        </td>
        {/* End Deskripsi Addcost */}

        {/* Start Addcost */}
        <td className={"text-center border align-start"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`, verticalAlign: "middle"}} rowSpan={totalRowSpanPerItem}>
          <input type={"text"}
                className={"form-control form-control-sm text-end"}
                disabled={true}
                value={parseNumberToCurrency(item?.additional_cost, item?.currency?.code)}
                style={{ minWidth: "150px" }}
          />
        </td>
        {/* End Addcost */}

        {/* Start Quantity */}
        <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
        {/* End Quantity */}

        {/* Start Buyer Price */}
        <td className={"border text-end"}>{parseNumberToCurrency(item?.buyer_valuation_price, item?.currency?.code)}</td>
        {/* End Buyer Price */}

        {/* Start Quantity */}
        <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
        {/* End Quantity */}

        {/* Start Quote Price */}
        <td className={`${parseFloat(item?.valuation_price) > parseFloat(item?.buyer_valuation_price) ? (
          'text-end border text-danger'
        ) : (
          'text-end border'
        )}`}>{parseNumberToCurrency(item?.valuation_price, item?.currency?.code)}</td>
        {/* End Quote Price */}
        
        {/* Start Quote Price */}
        <td className={`${parseFloat(item?.valuation_price) > parseFloat(item?.buyer_valuation_price) ? (
          'text-end border text-danger px-2'
        ) : (
          'text-end border px-2'
        )}`}>{parseNumberToCurrency(item?.total_value, item?.currency?.code)}</td>
        {/* End Quote Price */}
      </>
    )
  }

  const TableItemServiceLines = ({proposalTender, service, quotation, item, index,}: any) => {
    const [shortTextPR, setShortTextPR] = useState<any>(service?.short_text_pr);
    const [quantity, setQuantity] = useState<any>(service?.quantity);
    const [valuationPrice, setValuationPrice] = useState<any>(service?.net_price);
    const [totalPenawaran, setTotalPenawaran] = useState<any>(service?.gross_price);

    return (
      <>
        <tr key={index + "-" + service?.id} style={{
          position: "relative",
          height: "auto",
        }}>
          {/* Start Short Text */}
          <td className={"text-start border align-start bg-white px-2"} style={{
            verticalAlign: "middle", position: 'sticky', left: 30, zIndex: 1
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={shortTextPR}
              rows={1}
              disabled={true}
              maxLength={50}>
            </textarea>
          </td>
          {/* End Short Text */}

          {/* Start Currency */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
              className={"form-control form-control-sm"}
              disabled={true}
              value={service?.currency_id}
              style={{
                minWidth: "50px"
              }}
            />
          </td>
          {/* End Currency */}

          {/* Start UoM */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
              className={"form-control form-control-sm"}
              disabled={true}
              value={service?.uom_id}
              style={{
                minWidth: "50px"
              }}
            />
          </td>
          {/* End UoM */}

          {/* Start Qty */}
          <td className={"text-center border align-start"}>
            <CurrencyInput
              className="form-control form-control-sm text-end"
              groupSeparator="."
              decimalSeparator=","
              decimalsLimit={2}
              value={quantity}
              onValueChange={(value, name) => {
                setQuantity(value);
              }}
              style={{
                maxWidth: "150px"
              }}
              disabled={true}
            />
          </td>
          {/* End Qty */}

          {/* Start Buyer Price */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
              className={"form-control form-control-sm text-end"}
              disabled={true}
              value={parseNumberToCurrency(service?.gross_value_pr, service?.currency_id)}
              style={{ minWidth: "200px" }}
            />
          </td>
          {/* End Buyer Price */}

          {/* Start Qty */}
          <td className={"text-center border align-start"}>
            <CurrencyInput
              className="form-control form-control-sm text-end"
              groupSeparator="."
              decimalSeparator=","
              decimalsLimit={2}
              value={quantity}
              onValueChange={(value, name) => {
                setQuantity(value);
              }}
              style={{
                minWidth: "100px"
              }}
              disabled={true}
            />
          </td>
          {/* End Qty */}

          {/* Start Quote Price */}
          <td className={"text-center border align-start"}>
            <input type={"text"}
              className={"form-control form-control-sm text-end"}
              disabled={true}
              value={parseNumberToCurrency(valuationPrice, service?.currency_id)}
              style={{ minWidth: "200px" }}
            />
          </td>
          {/* End Quote Price */}

          {/* Start Quote totalPenawaran */}
          <td className={"text-center border align-start px-2"}>
            <input type={"text"}
                  className={"form-control form-control-sm text-end"}
                  disabled={true}
                  value={parseNumberToCurrency(totalPenawaran, service?.currency_id)}
                  style={{ minWidth: "200px" }}
            />
          </td>
          {/* End Quote totalPenawaran */}
        </tr>
      </>
    )
  }

  const TableItemMaterial = ({proposalTender, service, quotation, item, index,}: any) => {
    return (
      <>
        {/* Start No */}
        <td className={"border px-2 text-center"}
          style={{ position: "sticky", left: 0, zIndex: 1, backgroundColor: "#fff" }}
        >{index + 1}</td>
        {/* End No */}

        {/* Start Short Text */}
        <td className={"border"}
            style={{ position: "sticky",left: 30,zIndex: 1,backgroundColor: "#fff" }}>{item?.buyer_short_text}
        </td>
        {/* End Short Text */}

        {/* Start Buyer Specification */}
        <td className={"border"} style={{ maxHeight: `${40 * item.serviceLinesCount}px`}}>
          <div style={{
            maxHeight: `${40 * item.serviceLinesCount}px`,
            width: "250px",
            overflow: "auto"
          }}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.buyer_spesification}
              rows={1}
              disabled={true}
              maxLength={250}>
            </textarea>
          </div>
        </td>
        {/* End Buyer Specification */}

        {/* Start Comply or Deviate */}
        <td className={"border text-center text-nowrap"}>
          <strong>{item?.quote}</strong>
        </td>
        {/* End Comply or Deviate */}

        {/* Start Currency */}
        <td className={"border text-center text-nowrap"}>{item?.currency?.code}</td>
        {/* End Currency */}

        {/* Start UoM */}
        <td className={"border text-center text-nowrap"}>{item?.uom?.uom}</td>
        {/* End UoM */}

        {/* Start Per */}
        <td className={"border text-center text-nowrap"}>{item?.per}</td>
        {/* End Per */}

        {/* Start Deskripsi Addcost */}
        <td className={"text-center border align-start"} style={{ maxHeight: `40px`, verticalAlign: "middle"}}>
            <textarea
              className={"form-control form-control-sm"}
              defaultValue={item?.additional_cost_description}
              rows={2}
              disabled={true}
              maxLength={250}>
            </textarea>
        </td>
        {/* End Deskripsi Addcost */}

        {/* Start Addcost */}
        <td className={"text-center border align-start"} style={{ maxHeight: `40px`, verticalAlign: "middle"}}>
          <input type={"text"}
                className={"form-control form-control-sm text-end"}
                disabled={true}
                value={parseNumberToCurrency(item?.additional_cost, item?.currency?.code)}
                style={{ minWidth: "150px" }}
          />
        </td>
        {/* End Addcost */}

        {/* Start Quantity */}
        <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
        {/* End Quantity */}

        {/* Start Buyer Price */}
        <td className={"border text-end"}>{parseNumberToCurrency(item?.buyer_valuation_price, item?.currency?.code)}</td>
        {/* End Buyer Price */}

        {/* Start Quantity */}
        <td className={"border text-end"}>{parseNumberToFloat(item?.quantity)}</td>
        {/* End Quantity */}

        {/* Start Quote Price */}
        <td className={`${parseFloat(item?.valuation_price) > parseFloat(item?.buyer_valuation_price) ? (
          'text-end border text-danger'
        ) : (
          'text-end border'
        )}`}>{parseNumberToCurrency(item?.valuation_price, item?.currency?.code)}</td>
        {/* End Quote Price */}
        
        {/* Start Quote Price */}
        <td className={`${parseFloat(item?.valuation_price) > parseFloat(item?.buyer_valuation_price) ? (
          'text-end border text-danger px-2'
        ) : (
          'text-end border px-2'
        )}`}>{parseNumberToCurrency(item?.total_value, item?.currency?.code)}</td>
        {/* End Quote Price */}
      </>
    )
  }

  return (
    <>
      <Panel
        header={
          <Stack justifyContent={"space-between"}>
            <h3>{intl.formatMessage({ id: "Proses Evaluasi Komersil" })}</h3>
            {(durVendor?.status_evaluation_commercial === "PASSED" || durVendor?.status_evaluation_commercial === "FAILED") && (
              <div>
                <Badge className={"px-2 py-1"} content={
                  <>
                    <i className={"fa fa-check-circle text-white me-2"}></i>
                    {intl.formatMessage({ id: "Telah Dievaluasi" })}
                  </>
                } color="blue" />
              </div>
            )}
            {(durVendor?.status_evaluation_commercial === "NO-DATA" || durVendor?.status_evaluation_commercial === "NOT-EVALUATE") && (
              <div>
                <Badge className={"px-2 py-1"} content={
                  <>
                    <i className={"fa fa-times-circle text-white me-2"}></i>
                    {intl.formatMessage({ id: "Belum Dievaluasi" })}
                  </>
                } color="red" />
              </div>
            )}
          </Stack>
        }
        collapsible
        bordered
        defaultExpanded={true}
      >
        <div className={"table-responsive"}>
          <table className={"table table-bordered table-striped table-header-gray"}>
            <thead>
            <tr>
              <th
                className={'text-center border text-nowrap px-2'}
                style={{verticalAlign: 'middle', position: 'sticky', left: 0, zIndex: 1}}
                rowSpan={2}
              >
                <strong>&nbsp;{intl.formatMessage({id: 'No'})}</strong>
              </th>
              <th className={'border align-items-start'}
                  style={{verticalAlign: 'middle', minWidth: '250px', position: 'sticky', left: 30, zIndex: 1}}
                  rowSpan={2}>
                <strong>{intl.formatMessage({id: 'Deskripsi'})}</strong>
              </th>
              <th className={'text-center border'} rowSpan={2} style={{verticalAlign: 'middle', minWidth: '200px'}}>
                <strong>{intl.formatMessage({id: 'Spesifikasi'})}</strong>
              </th>
              <th className={'text-center border text-nowrap'} rowSpan={2} style={{verticalAlign: 'middle'}}>
                <strong>{intl.formatMessage({id: 'Quote'})}</strong>
              </th>
              <th className={'text-center border text-nowrap'} rowSpan={2} style={{verticalAlign: 'middle'}}>
                <strong>{intl.formatMessage({id: 'Mata Uang'})}</strong>
              </th>
              <th className={'text-center border'} rowSpan={2} style={{verticalAlign: 'middle'}}>
                <strong>{intl.formatMessage({id: 'Satuan'})}</strong>
              </th>
              <th className={'text-end border'} rowSpan={2} style={{verticalAlign: 'middle'}}>
                <strong>Per</strong>
              </th>
              <th className={'text-center border text-nowrap'} rowSpan={2} style={{verticalAlign: 'middle'}}>
                <strong>{intl.formatMessage({id: 'Deskripsi Add Cost'})}</strong>
              </th>
              <th className={'text-center border text-nowrap'} rowSpan={2} style={{verticalAlign: 'middle'}}>
                <strong>{intl.formatMessage({id: 'Add Cost'})}</strong>
              </th>
              <th className={'text-center border text-nowrap'} colSpan={2}>
                <strong>{intl.formatMessage({id: 'Permintaan Buyer'})}</strong>
              </th>
              <th className={'text-center border text-nowrap'} colSpan={3}>
                <strong>{intl.formatMessage({id: 'Penawaran Vendor'})}</strong>
              </th>
              <th
                className={'border text-center text-nowrap px-2'}
                style={{verticalAlign: 'middle', position: 'sticky', right: 0, zIndex: 1}}
                colSpan={(isPaketSistemGugur() || isItemizeSistemGugur()) ? 2 : 1}
                rowSpan={isPaketSistemNilai() || isItemizeSistemNilai() ? 2 : 1}
              >
                <strong>
                  {proposalTender?.metode_evaluasi === 'Sistem Nilai' ? intl.formatMessage({id: 'Nilai Evaluasi'}) : intl.formatMessage({id: 'Hasil Evaluasi'})}
                </strong>
              </th>
            </tr>
            <tr>
              <th className={'text-end border'} style={{
                minWidth: '100px',
              }}>{intl.formatMessage({id: 'Kuantitas'})}</th>
              <th className={'text-end border text-nowrap'}>{intl.formatMessage({id: 'Harga Satuan'})}</th>
              <th className={'text-end border'} style={{
                minWidth: '100px',
              }}>{intl.formatMessage({id: 'Kuantitas'})}</th>
              <th className={'text-end border text-nowrap'}>{intl.formatMessage({id: 'Harga Satuan'})}</th>
              <th className={'text-end border text-nowrap px-2'}>{intl.formatMessage({id: 'Total Harga'})}</th>
              {(isPaketSistemGugur() || isItemizeSistemGugur()) && (
                <>
                  <th
                    className={"text-center text-nowrap border"}
                    style={{ verticalAlign: "middle", position: "sticky", right: 85, zIndex: 1 }}
                  >
                    {isItemizeSistemGugur() &&
                      <>
                        <input type={"checkbox"} className={"form-check-input"} checked={isCheckAll} disabled={!isFormEnabled()}
                               onChange={handleCheckAll} /> {intl.formatMessage({ id: "Setujui Semua" })}
                      </>
                    }
                  </th>
                  <th
                    className={"text-center text-nowrap border"}
                    style={{ verticalAlign: "middle", position: "sticky", right: 0, zIndex: 1 }}
                  >
                    {isItemizeSistemGugur() &&
                      <>
                        <input type={"checkbox"} className={"form-check-input"} checked={isUncheckAll}
                               disabled={!isFormEnabled()} onChange={handleUncheckAll} /> {intl.formatMessage({ id: "Tolak Semua" })}
                      </>
                    }
                  </th>
                </>
              )}
            </tr>
            </thead>

            <tbody>
            {dataItems.map((item: any, index: number) => {
              let isBarang = (item?.service_lines_quotation?.length === 0)
              let isJasa = (item?.service_lines_quotation?.length > 0)
              let isBarangJasa = (item?.service_lines_quotation?.length > 0 && item?.service_lines_quotation[0]?.type === "Barang")

              let serviceLinesCount = item?.service_lines_quotation?.length === 0 ? 0 : item?.service_lines_quotation?.length;
              let totalRowSpanPerItem = serviceLinesCount + 1

              let totalServiceLinesAllItem = dataItems.reduce((total: any, item: {service_lines_quotation: string | any[]; }) => {
                  return total + ((item?.service_lines_quotation?.length === 0) ? 0 : item?.service_lines_quotation?.length);
                }, 0);

                let totalRowSpan              = totalServiceLinesAllItem + dataItems.length;

                return (
                  <>
                    <tr key={"item-evaluasi-komersil" + index}>
                      {(isBarang) && (
                        <TableItemMaterial proposalTender={proposalTender} service={item} quotation={quotation} item={item} index={index} />
                      )}
                      {(isJasa) && (
                        <TableItemService proposalTender={proposalTender} service={item} quotation={quotation} item={item} index={index} />
                      )}
                      {/*Start Nilai Evaluasi / Lolos,TidakLolos */}
                      {(isPaketSistemGugur() && index === 0) && (
                        <>
                          <td
                            className={"border text-center text-nowrap px-2"}
                            rowSpan={isJasa
                              ? totalRowSpan
                              : dataItems.length + 1
                            }
                            style={{
                              verticalAlign: "middle",
                              position: "sticky",
                              right: 85,
                              zIndex: 1,
                              backgroundColor: "#f8fafb"
                            }}
                          >
                            {isLoadingSave && (
                              <i className={"fa fa-spinner fa-spin"}></i>
                            )}
                            <input type={"checkbox"} className={"form-check-input"}
                                   checked={statusEvaluationCommercial === "PASSED"} disabled={!isFormEnabled()}
                                   onChange={(e: any) => {
                                     handleOnCheck(index, true);
                                   }} /> {intl.formatMessage({ id: "Diterima" })}
                          </td>
                          <td
                            className={"border text-center text-nowrap px-2"}
                            rowSpan={isJasa
                              ? totalRowSpan
                              : dataItems.length + 1
                            }
                            style={{
                              verticalAlign: "middle",
                              position: "sticky",
                              right: 0,
                              zIndex: 1,
                              backgroundColor: "#f8fafb"
                            }}
                          >
                            {isLoadingSave && (
                              <i className={"fa fa-spinner fa-spin"}></i>
                            )}
                            <input type={"checkbox"} className={"form-check-input"}
                                   checked={statusEvaluationCommercial === "FAILED"} disabled={!isFormEnabled()}
                                   onChange={(e: any) => {
                                     handleOnCheck(index, false);
                                   }} /> {intl.formatMessage({ id: "Ditolak" })}
                          </td>
                        </>
                      )}
                      {(isPaketSistemNilai() && index === 0) && (
                        <td className={"border text-center align-start text-nowrap px-2"}
                          rowSpan={isJasa
                            ? totalRowSpan
                            : isBarangJasa
                            ? totalRowSpan + 1
                            : dataItems.length + 1
                          }                    
                          style   ={{
                            verticalAlign: "middle",
                            position: "sticky",
                            right: 0,
                            zIndex: 1
                          }}>
                          {isLoadingSave && (
                            <i className={"fa fa-spinner fa-spin"}></i>
                          )}
                         -
                        </td>
                      )}
                      {isItemizeSistemGugur() && (
                        <>
                          <td
                            className={"border text-center text-nowrap px-2"}
                            rowSpan={isBarang ? 1 : totalRowSpanPerItem}
                            style={{
                              verticalAlign: "middle",
                              position: "sticky",
                              right: 90,
                              zIndex: 1,
                              backgroundColor: "#f8fafb"
                            }}
                          >
                            {isLoadingSave && (
                              <i className={"fa fa-spinner fa-spin"}></i>
                            )}
                            <input type={"checkbox"} className={"form-check-input"}
                                   checked={item?.commercial_status === "PASSED" || getDataEvaluationItem(index) === true}
                                   disabled={!isFormEnabled()}
                                   onChange={(e: any) => {
                                     handleOnCheck(index, true);
                                   }} /> Diterima
                          </td>
                          <td
                            className={"border text-center text-nowrap px-2"}
                            rowSpan={isBarang ? 1 : totalRowSpanPerItem}
                            style={{
                              verticalAlign: "middle",
                              position: "sticky",
                              right: 0,
                              zIndex: 1,
                              backgroundColor: "#f8fafb"
                            }}
                          >
                            {isLoadingSave && (
                              <i className={"fa fa-spinner fa-spin"}></i>
                            )}
                            <input type={"checkbox"} className={"form-check-input"}
                                   checked={item?.commercial_status === "FAILED" || getDataEvaluationItem(index) === false}
                                   disabled={!isFormEnabled()}
                                   onChange={(e: any) => {
                                     handleOnCheck(index, false);
                                   }} /> Tidak Diterima
                          </td>
                        </>
                      )}
                      {(isItemizeSistemNilai()) && (
                        <td className={"border text-center align-start text-nowrap px-2"}
                            style={{ verticalAlign: "middle", position: "sticky", right: 0, zIndex: 1 }}
                            rowSpan={isBarang ? 1 : totalRowSpanPerItem}
                            key={"render-"+dataItems[index]+'-'+renderKey}
                        >
                          {isLoadingSave && (
                            <i className={"fa fa-spinner fa-spin"}></i>
                          )}
                          {isFormEnabled() && (
                            <>-</>
                          )}
                          {!isFormEnabled() && (
                            <div className={"bg-white"}>
                              {dataItems[index]?.score_commercial_evaluation}
                            </div>
                          )}

                        </td>
                      )}
                      {/*End Nilai Evaluasi / Lolos,TidakLolos */}
                    </tr>
                    {(item?.service_lines_quotation && item?.service_lines_quotation?.length > 0)  && item?.service_lines_quotation?.map((service: any, index2: number) => {
                      return (
                        <TableItemServiceLines
                          service={service}
                          index={index2}
                          indexItem={index}
                          key={index2 + "-" + service?.id}
                          quotation={quotation}
                          item={item}
                          proposalTender={proposalTender}
                        />
                      );
                    })}
                  </>
                );
              })}
            </tbody>
            <tfoot>
              <td 
                className={"text-center border align-end"} 
                style={{backgroundColor: '#0e365b', fontWeight: 'bold', fontSize: '20px', color: "white", verticalAlign: "middle", position: "sticky", left: 0, zIndex: 1}} 
                colSpan={3}
              > Total Penawaran Vendor</td>
              <td 
                className={"text-end border align-start px-20"} 
                style={{backgroundColor: '#0e365b', fontWeight: 'bold', fontSize: '20px', color: 'white', verticalAlign: "middle", position: "sticky", right: 0, zIndex: 0}} 
                colSpan={13}
              ><strong></strong>{getTotalValueFromVendorQuotation()}</td>
            </tfoot>
          </table>
        </div>

        <div className={"table-responsive mt-10"}>
          <table className={"table table-bordered table-header-gray"}>
            <thead>
            <tr>
              <th className={"text-center border"}><strong>No</strong></th>
              <th className={"text-center border"}><strong>Deskripsi</strong></th>
              <th className={"text-center border"}><strong>Mandatory</strong></th>
              <th className={"text-center border"}><strong>Dokumen</strong></th>
              <th className={"text-center border"} colSpan={2}><strong>Hasil Evaluasi</strong></th>
            </tr>
            </thead>
            <tbody>
            {syarats.length === 0 && (
              <tr>
                <td className={"text-center border"} colSpan={5}>Tidak ada data.</td>
              </tr>
            )}
            {syarats.map((syarat: any, index: number) => {
              return (
                <tr key={"evaluasi-kommersial" + index}>
                  <td className={"text-center border"}>{index + 1}</td>
                  <td className={"text-left border"}>{syarat.description}</td>
                  <td className={"text-center border"}>{syarat.required_attachment ? "Ya" : "Tidak"}</td>
                  <td className={"text-center border"}>
                    {syarat?.media_url && (
                      <div className={"input-group input-group-sm"}>
                        <input type={"text"} className={"form-control form-control-sm"} value={syarat?.description}
                               disabled={true} />
                        <button type={"button"} className={"btn btn-primary btn-sm"} disabled={isLoading}
                                onClick={() => setPreviewAttachment(
                                  syarat?.media_url
                                )}>
                          <i className={"fa fa-eye"}></i>
                        </button>
                      </div>
                    )}
                    {!syarat?.media_url &&
                      <div className={"text-gray"} style={{
                        fontStyle: "italic",
                        color: "#a1a5b7"
                      }}><i className={"fa fa-file"}></i> Belum ada file tersedia.</div>
                    }
                  </td>
                  <td className={"border text-center"}>
                    <div className={"d-flex align-items-center justify-content-center gap-2"}>
                      {isLoadingSave && (
                        <i className={"fa fa-spinner fa-spin"}></i>
                      )}
                      <input type={"checkbox"} className={"form-check-input"} checked={syarat?.status === "PASSED"}
                             disabled={!isFormEnabled()} onChange={(e: any) => {
                        updateSyarat(index, "PASSED");
                      }} /> Diterima
                    </div>
                  </td>
                  <td className={"border text-center"}>
                    <div className={"d-flex align-items-center justify-content-center gap-2"}>
                      {isLoadingSave && (
                        <i className={"fa fa-spinner fa-spin"}></i>
                      )}
                      <input type={"checkbox"} className={"form-check-input"} checked={syarat?.status === "FAILED"}
                             disabled={!isFormEnabled()} onChange={(e: any) => {
                        updateSyarat(index, "FAILED");
                      }} /> Tidak Diterima
                    </div>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
        <NoteAttachmentEvaluation
          proposalTender={proposalTender}
          quotation={quotation}
          onChange={(type:string,value:any)=>{
            setNote(value?.note);
            setAttachment(value?.attachments);
          }}
          onDeleted={(deletedAttachment: any) => {
            setDeletedAttachment(deletedAttachment)
          }}
          fieldKey={"evaluation_commercial"}
        />

        <EvaluasiKlarifikasiAdministrasiTeknisList
          vendor_id={quotation?.vendor_id}
          clarificationType={"commercial"}
        />

        <PreviewAttachment previewExt={previewAttachment} onHide={setPreviewAttachment} />
        {isOwner &&
          <div className={"mt-4 d-flex justify-content-end"}>
            <button
              className={"btn btn-primary"}
              type={"button"}
              onClick={handleOnSubmitEvaluasiKomersil}
              disabled={isLoadingSave}
            >
              {isLoadingSave && (
                <Spinner animation="border" role="status" size={"sm"} className={"me-2"} />
              )}
              {!isLoadingSave && <i className={"fa fa-save"}></i>}
              {intl.formatMessage({ id: "Simpan Evaluasi Komersil" })}
            </button>
          </div>
        }
      </Panel>
    </>
  );
};

export default EvaluasiKomersil;