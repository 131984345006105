import React, { useEffect, useState } from "react";
import {Nav, Placeholder} from 'rsuite'
import ResumeNegosiasi from "./ResumeNegosiasiTab";
import SesiNegosiasiTab from "./SesiNegosiasiTab";
import ProsesNegosiasiTab from "./ProsesNegosiasiTab";
import { useIntl } from "react-intl";
import { BeritaAcaraTab } from "./BeritaAcaraTab";
import {useAxiosGet} from '../../../../../../../../hooks/useAxios'
import {getEnv} from '../../../../../../../../helpers/procurex'
import {toast} from 'react-toastify'
import {Alert, Button} from 'react-bootstrap'
import {PageAlertX} from '../../../../../../../components/shared/PageAlertX'
import {useProposalTender} from '../../../core/_proposalTenderProvider'


const NegosiasiPage = ({onSetKonfigurasiNego, isOwner }: any) => {
  const {proposalTender} = useProposalTender()
  const [selectedTab, setSelectedTab] = useState("resume-negosiasi");
  const [config, setConfig] = useState({} as any)
  const [selectedVendor, setSelectedVendor] = useState([] as any)
  const intl = useIntl();
  const [isProsesNegosiasiEnabled, setIsProsesNegosiasiEnabled] = useState(false);
  const [{ doSubmit, isLoading }] = useAxiosGet(
    getEnv("tender")
  )
  const [auction, setAuction] = useState(null)
  const handleSetConfigFromItems = (vendors:any, items:any) => {
    setSelectedVendor(vendors)
  }

  const handleConfigNegoForm = (config:any) => {
    const newConfig = {
      ...config,
      vendors: selectedVendor
    }
    setConfig(newConfig)
  }

  useEffect(() => {
    setConfig({
      ...config,
      vendors: selectedVendor
    })
  },[selectedVendor])

  const checkIsProsesNegosiasiEnabled = (_proposalTender:any) => {
    console.log(_proposalTender?.status,_proposalTender?.status_detail)
    const allowedStatus = [
      "Negotiation"
    ];
    const allowedStatusDetail = [
      "Proses Negosiasi"
    ]
    return allowedStatus.includes(_proposalTender.status) && allowedStatusDetail.includes(_proposalTender.status_detail)
  }


  const handleOnSaveSesiNegosiasi = async(config:any) => {
    setSelectedTab("sesi-negosiasi")
    onSetKonfigurasiNego(config)
    setIsProsesNegosiasiEnabled(true)
  }

  const getAuction = () => {
    if(proposalTender?.status === "Negotiation" && proposalTender?.status_detail === "Auction"){
      doSubmit({},getEnv('auction')+`/auction?sort=-created_at&&filter[no_tender]=${proposalTender?.no}`)
        .then((res:any)=>{
          setAuction(res?.data[0])
        })
    }
  }

  useEffect(() => {
    console.log(config)
    // onSetKonfigurasiNego(config)
  }, [config])

  useEffect(() => {
    if(!proposalTender) return ;
    getAuction()
    if(proposalTender?.status === "Negotiation" && proposalTender?.status_detail === "Proses Negosiasi"){
      setIsProsesNegosiasiEnabled(true)
    } else {
      setIsProsesNegosiasiEnabled(false) 
    }
  }, [proposalTender])

  return (
    <>
      {isLoading && (
        <Placeholder.Grid rows={5} columns={5} />
      )}
      {(!isLoading && proposalTender) && (
        <div className={""}>
          {auction && (
            <div>
              <PageAlertX
                variant={'info'}
                title={
                  <>
                    <i className={'fa fa-exclamation-triangle text-info blink_me me-2 fs-2'}></i>
                    Tender ini dalam proses <strong>Negosiasi via Auction</strong> dengan nomor {auction?.no_auction}
                  </>
                }
                html={
                  <div className={'pt-2'}>
                    <Button variant={'info'} size={'sm'} onClick={() => {
                      window.open(`/auction-v2/auction/${auction?.id}`)
                    }}>
                      <i className={'fa fa-link me-2'}></i>
                      Lihat Auction
                    </Button>
                  </div>
                } />
            </div>
          )}
          <Nav appearance={'subtle'} activeKey={selectedTab} onSelect={(e: any) => {
            setSelectedTab(e)
          }} style={{marginBottom: 20}}>
            <Nav.Item eventKey={'resume-negosiasi'}>
              {intl.formatMessage({id: 'Resume Negosiasi'})}
            </Nav.Item>
            <Nav.Item eventKey={'sesi-negosiasi'}>
              {intl.formatMessage({id: 'Sesi Negosiasi'})}
            </Nav.Item>
            <Nav.Item eventKey={'proses-negosiasi'} disabled={!isProsesNegosiasiEnabled}>
              {intl.formatMessage({id: 'Proses Negosiasi'})}
            </Nav.Item>
            <Nav.Item eventKey={'berita-acara'}>
              {intl.formatMessage({id: 'Berita Acara'})}
            </Nav.Item>
          </Nav>

          {selectedTab === "resume-negosiasi" && (
            <ResumeNegosiasi proposalTender={proposalTender} />
          )}
          {selectedTab === "sesi-negosiasi" && (
            <SesiNegosiasiTab
              proposalTender={proposalTender}
              onSetConfigFromItems={handleSetConfigFromItems}
              onChange={handleOnSaveSesiNegosiasi}
              isOwner={isOwner}
            />
          )}
          {selectedTab === "proses-negosiasi" && (
            <ProsesNegosiasiTab
              proposalTender={proposalTender}
              onSetConfigNegoForm={handleConfigNegoForm}
              onChange={handleOnSaveSesiNegosiasi}
              isOwner={isOwner}
            />
          )}
          {selectedTab === "berita-acara" && (
            <BeritaAcaraTab proposalTender={proposalTender} isOwner={isOwner}/>
          )}
        </div>
      )}
    </>
  );
};
export default NegosiasiPage;