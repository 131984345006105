import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import { Accordion, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import axios, { AxiosResponse } from 'axios'
import { Field, Form, Formik } from 'formik'
import { ApiUrl } from '../../../../../../config/Api'
import DetailFormItemModal from '../DetailFormItemModal'
import FormikSelect2 from '../../../../../../components/shared/form/FormikSelect2'
import * as Yup from 'yup'
import moment from 'moment'
import { parseNumberToCurrency, parseNumberToFloat } from '../../../../../../../helpers/procurex'
import { MetronicErrorMessage, toastError, toastSuccess } from '../../../../../../../_metronic/helpers'
import { Placeholder } from 'rsuite'
import { useAuth } from '../../../../../auth'

interface Props {
  ref: any
  data: any
  headerLoading: any
}

const headerFormSchema = Yup.object().shape({
  purchasing_group: Yup.object().required('This field is required').nullable(),
  purchasing_organization: Yup.object().required('This field is required').nullable(),
  document_type: Yup.object().required('This field is required').nullable(),
  payment_term: Yup.object().required('This field is required').nullable(),
  company: Yup.object().required('This field is required').nullable(),
  incoterm: Yup.object().required('This field is required').nullable(),
  incoterm_description: Yup.string().required('This field is required'),
  header_text: Yup.string().required('This field is required'),
})

const HeaderForm = forwardRef(({ data, headerLoading }: Props, refHeader) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [items, setItems] = useState<any>([])
  const [modalData, setModalData] = useState('')
  const [itemLoading, setItemLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [modalFormDetailItem, setModalFormDetailItem] = useState(false)

  const [initialValues, setInitialValues] = useState({
    no: '',
    sap_no: '',
    status: '',

    purchasing_organization: '',
    purchasing_group: '',
    document_type: '',
    payment_term: '',
    company: '',
    incoterm: '',
    incoterm_description: '',
    header_text: '',
  })

  const loadItem = (id: any) => {
    axios
      .get(`${ApiUrl}/api/tender/po-item?filter[purchase_order_id]=${id}&perPage=1000`)
      .then((res) => {
        setItems(res.data.data)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setItemLoading(false)
      })
  }

  useEffect(() => {
    if (data?.incoterm && typeof data?.incoterm === 'string') {
      data.incoterm = JSON.parse(data?.incoterm)
    }
    setInitialValues(data)
  }, [data])

  useEffect(() => {
    if (data) {
      setItemLoading(true)
      loadItem(data?.id)
    }
  }, [initialValues])

  useImperativeHandle(refHeader, () => ({
    async submitHeaderForm(force: boolean = false) {
      if (!force) {
        return
      }

      await formik.current.handleSubmit()
    },
  }))

  const formik = useRef({} as any)

  const submitForm = async (values: any) => {
    const data = {
      purchasing_group_id: values?.purchasing_group?.purch_group_id,
      purchasing_organization_id: values?.purchasing_organization?.comp_code,
      payment_term_id: values?.payment_term?.uuid,
      incoterm_id: values?.incoterm?.id,
      incoterm_description: values?.incoterm_description,
      document_type_id: values?.document_type?.id,
      company_id: values?.company?.comp_code,
      header_text: values?.header_text,
    }

    try {
      let response: AxiosResponse<any, any> | null = null
      if (id) {
        response = await axios.put(`${ApiUrl}/api/tender/po/${id}`, data)
      } else {
        response = await axios.post(`${ApiUrl}/api/tender/po`, data)
      }

      toastSuccess(response?.data?.meta?.message)

      navigate('/contract-management/po/' + response?.data.data.uuid)
      setErrorMessage('')
    } catch (error: any) {
      console.error(error)
      window.scrollTo(0, 0)
      toastError(error?.response?.data?.meta?.message || 'Terjadi kesalahan')
    }
  }

  return (
    <>
      {modalFormDetailItem && (
        <DetailFormItemModal
          show={modalFormDetailItem}
          handleClose={() => setModalFormDetailItem(false)}
          header={data}
          data={modalData}
        />
      )}

      <div>
        <Formik
          enableReinitialize
          validationSchema={headerFormSchema}
          initialValues={initialValues}
          innerRef={formik}
          onSubmit={submitForm}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              {errorMessage && <div className='alert alert-danger'>{errorMessage}</div>}

              <Accordion defaultActiveKey={['0']} className='mb-5'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header><h3>Header</h3></Accordion.Header>
                  <Accordion.Body>
                    <div className='card'>
                      <div className='card-body'>
                        <Row>
                          <Col lg={6}>
                            <div className='mb-10'>
                              <label htmlFor='exampleFormControlInput1' className='form-label'>
                                <span>Nomor PO ProcureX</span>
                              </label>
                              {headerLoading ? (
                                <Placeholder.Paragraph />
                              ) : (
                                <Field
                                  disabled
                                  name='no'
                                  type='text'
                                  className='form-control'
                                  placeholder=''
                                />
                              )}
                            </div>
                            <div className='mb-10'>
                              <label htmlFor='exampleFormControlInput1' className='form-label'>
                                <span>Status</span>
                              </label>
                              {headerLoading ? (
                                <Placeholder.Paragraph />
                              ) : (
                                <Field
                                  disabled
                                  name='status'
                                  type='text'
                                  className='form-control'
                                  placeholder=''
                                />
                              )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className='mb-10'>
                              <label htmlFor='exampleFormControlInput1' className='form-label'>
                                <span>No PO SAP</span>
                              </label>
                              {headerLoading ? (
                                <Placeholder.Paragraph />
                              ) : (
                                <Field
                                  disabled
                                  name='sap_no'
                                  type='text'
                                  className='form-control'
                                  placeholder=''
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey={['0']} className='mb-5'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header><h3>Purchase Order (Header)</h3></Accordion.Header>
                  <Accordion.Body>
                    <div className='card'>
                      <div className='card-body'>
                        <Row>
                          <div style={{ minWidth: "100%" }}>
                            <table
                              className={"table table-row-bordered table-rounded table-striped border gs-5 table-sm p-0 align-middle"}
                            >
                              <tbody>
                                <tr>
                                  <td style={{ width: "150px", fontWeight: "500" }}>Source</td>
                                  <td style={{ paddingLeft: "20px" }}>{headerLoading ? <Placeholder.Paragraph /> : data?.source}</td>
                                  <td style={{ width: "150px", fontWeight: "500" }}>Payment Term <span className='text-danger'>*</span></td>
                                  <td>
                                    {headerLoading ?
                                      <Placeholder.Paragraph />
                                      :
                                      <FormikSelect2
                                        name={`payment_term`}
                                        optionsUrl={`${ApiUrl}/api/masterdata/term-of-payments`}
                                        getOptionValue={(opt: any) => opt}
                                        getOptionLabel={(opt: any) => `${opt.code} - ${opt.description}`}
                                        search_property={'description'}
                                        border={false}
                                        isDisabled={data?.status !== 'Draft' && data?.status !== 'PO Rejected'}
                                      />
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "150px", fontWeight: "500" }}>No Tender</td>
                                  <td style={{ paddingLeft: "20px" }}>
                                    {headerLoading
                                      ? <Placeholder.Paragraph />
                                      : <a href={"javascript:;"} title={"Edit"} onClick={() => { navigate("/tender/admin/proposal-tender/detail/" + data?.uuid_tender) }} className={`text-info`}>
                                          { data?.proposal_tender_no }
                                        </a>}
                                  </td>
                                  <td style={{ width: "150px", fontWeight: "500" }}>Purch Group <span className='text-danger'>*</span></td>
                                  <td>
                                    {headerLoading ?
                                      <Placeholder.Paragraph />
                                      :
                                      <FormikSelect2
                                        name={`purchasing_group`}
                                        optionsUrl={`${ApiUrl}/api/masterdata/purchasing-group`}
                                        getOptionValue={(opt: any) => opt}
                                        getOptionLabel={(opt: any) => `${opt.purch_group_id} - ${opt.purch_group_name}`}
                                        search_property={'purch_group_id_category_name'}
                                        border={false}
                                        isDisabled={data?.status !== 'Draft' && data?.status !== 'PO Rejected'}
                                      />
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "150px", fontWeight: "500" }}>Vendor</td>
                                  <td style={{ paddingLeft: "20px" }}>{headerLoading ? <Placeholder.Paragraph /> : (data?.vendor ? data?.vendor?.vendor_no + ' - ' + data?.vendor?.name : '')}</td>
                                  {!currentUser?.has_roles?.includes('vendor') ? (
                                    <>
                                      <td style={{ width: "150px", fontWeight: "500" }}>Doc Type PO <span className='text-danger'>*</span></td>
                                      <td>
                                        {headerLoading ?
                                          <Placeholder.Paragraph />
                                          :
                                          <FormikSelect2
                                            name={`document_type`}
                                            optionsUrl={`${ApiUrl}/api/masterdata/document-types?filter[cat]=F&filter[company]=${values?.company?.comp_code}`}
                                            getOptionValue={(opt: any) => opt}
                                            getOptionLabel={(opt: any) =>
                                              `${opt.doc_type_id} - ${opt.doc_type_desc}`
                                            }
                                            unique_property='id'
                                            search_property={'doc_type_id_desc'}
                                            border={false}
                                            isDisabled={data?.status !== 'Draft' && data?.status !== 'PO Rejected'}
                                          />
                                        }
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td style={{ width: "150px", fontWeight: "500" }}>Incoterm 1 <span className='text-danger'>*</span></td>
                                      <td>
                                        {headerLoading ?
                                          <Placeholder.Paragraph />
                                          :
                                          <FormikSelect2
                                            name={`incoterm`}
                                            optionsUrl={`${ApiUrl}/api/masterdata/incoterm`}
                                            getOptionValue={(opt: any) => opt}
                                            getOptionLabel={(opt: any) => `${opt.code} - ${opt.name}`}
                                            search_property={'code_name'}
                                            border={false}
                                            isDisabled={data?.status !== 'Draft' && data?.status !== 'PO Rejected'}
                                          />
                                        }
                                      </td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{ width: "150px", fontWeight: "500" }}>No Quotation</td>
                                  <td style={{ paddingLeft: "20px" }}>{headerLoading ? <Placeholder.Paragraph /> : data?.quotation_no}</td>
                                  {!currentUser?.has_roles?.includes('vendor') ? (
                                    <>
                                      <td style={{ width: "150px", fontWeight: "500" }}>Incoterm 1 <span className='text-danger'>*</span></td>
                                      <td>
                                        {headerLoading ?
                                          <Placeholder.Paragraph />
                                          :
                                          <FormikSelect2
                                            name={`incoterm`}
                                            optionsUrl={`${ApiUrl}/api/masterdata/incoterm`}
                                            getOptionValue={(opt: any) => opt}
                                            getOptionLabel={(opt: any) => `${opt.code} - ${opt.name}`}
                                            search_property={'code_name'}
                                            border={false}
                                            isDisabled={data?.status !== 'Draft' && data?.status !== 'PO Rejected'}
                                          />
                                        }
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td style={{ width: "150px", fontWeight: "500" }}>Location<span className='text-danger'>*</span></td>
                                      <td>
                                        {headerLoading ?
                                          <Placeholder.Paragraph />
                                          :
                                          <>
                                            <Field
                                              style={{ minWidth: "50px", border: 'none', minHeight: "41.2px" }}
                                              name={'incoterm_description'}
                                              type="text"
                                              className="form-control form-control-sm"
                                              placeholder="Incoterm 2"
                                              disabled={data?.status !== 'Draft' && data?.status !== 'PO Rejected'}
                                            />
                                            <MetronicErrorMessage name='incoterm_description' />
                                          </>
                                        }
                                      </td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{ width: "150px", fontWeight: "500" }}>Company Code <span className='text-danger'>*</span></td>
                                  <td>
                                    {headerLoading ?
                                      <Placeholder.Paragraph />
                                      :
                                      <FormikSelect2
                                        name={`company`}
                                        optionsUrl={
                                          data?.proposal_tender_no?.charAt(0) === "A"
                                            ? `${ApiUrl}/api/masterdata/companies`
                                            : `${ApiUrl}/api/masterdata/companies?filter[comp_code]=${data?.proposal_tender_no?.charAt(0)}000`
                                        }
                                        getOptionValue={(opt: any) => opt}
                                        getOptionLabel={(opt: any) => `${opt.comp_code} - ${opt.name}`}
                                        search_property={'name'}
                                        border={false}
                                        isDisabled={data?.status !== 'Draft' && data?.status !== 'PO Rejected'}
                                      />
                                    }
                                  </td>
                                  {!currentUser?.has_roles?.includes('vendor') ? (
                                    <>
                                      <td style={{ width: "150px", fontWeight: "500" }}>Location<span className='text-danger'>*</span></td>
                                      <td>
                                        {headerLoading ?
                                          <Placeholder.Paragraph />
                                          :
                                          <>
                                            <Field
                                              style={{ minWidth: "50px", border: 'none', minHeight: "41.2px" }}
                                              name={'incoterm_description'}
                                              type="text"
                                              className="form-control form-control-sm"
                                              placeholder="Incoterm 2"
                                              disabled={data?.status !== 'Draft' && data?.status !== 'PO Rejected'}
                                            />
                                            <MetronicErrorMessage name='incoterm_description' />
                                          </>
                                        }
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td></td>
                                      <td></td>
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  <td style={{ width: "150px", fontWeight: "500" }}>Purch Org <span className='text-danger'>*</span></td>
                                  <td>
                                    {headerLoading ?
                                      <Placeholder.Paragraph />
                                      :
                                      <FormikSelect2
                                        name={`purchasing_organization`}
                                        optionsUrl={
                                          data?.proposal_tender_no?.charAt(0) === "A"
                                            ? `${ApiUrl}/api/masterdata/companies`
                                            : `${ApiUrl}/api/masterdata/companies?filter[comp_code]=${data?.proposal_tender_no?.charAt(0)}000`
                                        }
                                        getOptionValue={(opt: any) => opt}
                                        getOptionLabel={(opt: any) => `${opt.comp_code} - ${opt.name}`}
                                        search_property={'name'}
                                        border={false}
                                        isDisabled={data?.status !== 'Draft' && data?.status !== 'PO Rejected'}
                                      />
                                    }
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td style={{ width: "150px", fontWeight: "500" }}>Header Text <span className='text-danger'>*</span></td>
                                  <td colSpan={3}>
                                    {headerLoading ?
                                      <Placeholder.Paragraph />
                                      :
                                      <>
                                        <Field
                                          as="textarea"
                                          name="header_text"
                                          id="header_text"
                                          className="form-control"
                                          disabled={data?.status !== 'Draft' && data?.status !== 'PO Rejected'}
                                        />
                                        <MetronicErrorMessage name='header_text' />
                                      </>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey={['0']} className='mb-5'>
                <Accordion.Item eventKey='0'>
                  <Accordion.Header><h3>Purchase Order (Item)</h3></Accordion.Header>
                  <Accordion.Body>
                    <div className='card'>
                      <div className='card-body'>
                        <div className="mt-10 table-responsive" style={{ minWidth: "100%" }}>
                          <table className={"table table-bordered table-rounded table-header-gray gs-5"}>
                            {items[0]?.serviceLines?.length === 0 ? (
                              <>
                                <thead>
                                  <tr>
                                    <th className={"text-center border px-2"} style={{ minWidth: "75px" }}>Action</th>
                                    <th className={"text-left border"} style={{ minWidth: "200px" }}><strong>Resume</strong></th>
                                    <th className={"text-left border"} style={{ minWidth: "100px" }}><strong>Description</strong></th>
                                    <th className={"text-left border"} style={{ minWidth: "200px" }}><strong>Material PO Text</strong></th>
                                    <th className={"text-left border"}><strong>Qty</strong></th>
                                    <th className={"text-left border"} style={{ minWidth: "100px" }}><strong>Satuan</strong></th>
                                    <th className={"text-left border"} style={{ minWidth: "150px" }}><strong>Harga Satuan</strong></th>
                                    <th className={"text-left border"}><strong>Currency</strong></th>
                                    <th className={"text-left border"} style={{ minWidth: "150px" }}><strong>Sub Total</strong></th>
                                    <th className={"text-left border"} style={{ minWidth: "150px" }}><strong>Add Cost</strong></th>
                                    <th className={"text-left border"} style={{ minWidth: "150px" }}><strong>Total</strong></th>
                                    <th className={"text-left border"}><strong>Delivery</strong></th>
                                    <th className={"text-left border"} style={{ minWidth: "100px" }}><strong>Tax Code</strong></th>
                                    <th className={"text-left border"} style={{ minWidth: "200px" }}><strong>Notes</strong></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {itemLoading ? (
                                    <tr>
                                      <td className={"border text-center"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-center"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                    </tr>
                                  ) : (
                                    <>
                                      {items && items?.map((item: any, index: any) => (
                                        <tr key={index}>
                                          <td className={"border text-center"}>
                                            <div>
                                              <OverlayTrigger
                                                placement='right'
                                                overlay={<Tooltip id='button-tooltip-2'>Item PO</Tooltip>}
                                              >
                                                <a href={"#"} title={"Lihat Detail"} onClick={() => {
                                                  setModalData(item)
                                                  setModalFormDetailItem(true)
                                                }}>
                                                  <i className={"fa fs-3 fa-pencil text-dark"}></i>
                                                </a>
                                              </OverlayTrigger>
                                            </div>
                                          </td>
                                          <td className={"border text-start"} style={{
                                            verticalAlign: 'top',
                                            borderBottom: '4px #dee2e6 !important',
                                          }}>
                                            <div className={"d-flex flex-column"}>
                                              {!currentUser?.has_roles?.includes('vendor') &&
                                                <>
                                                  <div className={"p-0 m-0 d-flex flex-column border-bottom mb-2"}>
                                                    <small className={"text-muted"}>Nomor PR</small>
                                                    {item?.no_pr || "-"}
                                                  </div>
                                                  <div className={"p-0 m-0 d-flex flex-column border-bottom mb-2"}>
                                                    <small className={"text-muted"}>Item PR</small>
                                                    {item?.line_no || "-"}
                                                  </div>
                                                </>
                                              }
                                              <div className={"p-0 m-0 d-flex flex-column border-bottom mb-2"}>
                                                <small className={"text-muted"}>Nomor Material</small>
                                                {item?.material_id || "-"}
                                              </div>
                                              <div className={"p-0 m-0 d-flex flex-column mb-2"}>
                                                <small className={"text-muted"}>Plant</small>
                                                {(item?.plant?.kode_plant + ' - ' + item?.plant?.nama_plant) || "-"}
                                              </div>
                                            </div>
                                          </td>
                                          <td className={"border text-start"}>{item?.item_text}</td>
                                          <td className={"border text-start"} dangerouslySetInnerHTML={{ __html: item?.specification.split('\n').join('<br />') }} />
                                          <td className={"border text-start"}>{parseNumberToFloat(item?.quantity)}</td>
                                          <td className={"border text-start"}>{item?.uom?.uom + ' - ' + item?.uom?.uom_name}</td>
                                          <td className={"border text-start"}>{parseNumberToCurrency(item?.valuation_price)}</td>
                                          <td className={"border text-start"}>{item?.currency?.code}</td>
                                          <td className={"border text-start"}>{parseNumberToCurrency(item?.total_value)}</td>
                                          <td className={"border text-start"}>{parseNumberToCurrency(item?.additional_cost)}</td>
                                          <td className={"border text-start"}>{parseNumberToCurrency(parseInt(item?.total_value) + parseInt(item?.additional_cost || '0'))}</td>
                                          <td className={"border text-start"}>{item?.delivery_date ? moment(item?.delivery_date).format('DD/MM/yyyy') : ''}</td>
                                          <td className={"border text-start"}>{item?.tax_code_id}</td>
                                          <td className={"border text-start"}>{item?.note}</td>
                                        </tr>
                                      ))}
                                    </>
                                  )}
                                </tbody>
                              </>
                            ) : (
                              <>
                                <thead>
                                  <tr>
                                    <th
                                      className={"text-center border px-2"}
                                      style={{ minWidth: "75px", position: "sticky", left: 0, zIndex: 1 }}
                                    >Action</th>
                                    <th
                                      className={"text-left border"}
                                      style={{ minWidth: "200px", position: "sticky", left: "75px", zIndex: 1 }}
                                    >Resume</th>
                                    <th className={"text-left border"} style={{ minWidth: "200px" }}>Description</th>
                                    <th className={"text-left border"}>Qty</th>
                                    <th className={"text-left border"} style={{ minWidth: "100px" }}>Satuan</th>
                                    <th className={"text-left border"} style={{ minWidth: "150px" }}>Harga Satuan</th>
                                    <th className={"text-left border"} style={{ minWidth: "150px" }}>Sub Total</th>
                                    <th className={"text-left border"}>Currency</th>
                                    <th className={"text-left border"} style={{ minWidth: "150px" }}>Add Cost</th>
                                    <th className={"text-left border"} style={{ minWidth: "150px" }}>Total</th>
                                    <th className={"text-left border"}>Delivery</th>
                                    <th className={"text-left border"} style={{ minWidth: "100px" }}>Tax Code</th>
                                    <th className={"text-left border"} style={{ minWidth: "200px" }}>Notes</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {itemLoading ? (
                                    <>
                                      <tr>
                                        <td className={"border text-center"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-center"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"} colSpan={4}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                        <td className={"border text-start"}>{<Placeholder.Paragraph />}</td>
                                      </tr>
                                    </>
                                  ) : (
                                    <>
                                      {items && items?.map((item: any, index: any) => (
                                        <>
                                          <tr key={index}>
                                            <td className={"border text-center"} rowSpan={item?.serviceLines?.length + 1} style={{
                                              position: "sticky", left: 0, zIndex: 1, backgroundColor: "#fff"
                                            }}>
                                              <div>
                                                <OverlayTrigger
                                                  placement='right'
                                                  overlay={<Tooltip id='button-tooltip-2'>Item PO</Tooltip>}
                                                >
                                                  <a href={"#"} title={"Lihat Detail"} onClick={() => {
                                                    setModalFormDetailItem(true)
                                                    setModalData(item)
                                                  }}>
                                                    <i className={"fa fs-3 fa-pencil text-dark"}></i>
                                                  </a>
                                                </OverlayTrigger>
                                              </div>
                                            </td>
                                            <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1} style={{
                                              verticalAlign: 'top',
                                              borderBottom: '4px #dee2e6 !important',
                                              position: "sticky", left: "75px", zIndex: 1, backgroundColor: "#fff"
                                            }}>
                                              <div className={"d-flex flex-column"}>
                                                {!currentUser?.has_roles?.includes('vendor') &&
                                                  <>
                                                    <div className={"p-0 m-0 d-flex flex-column border-bottom mb-2"}>
                                                      <small className={"text-muted"}>Nomor PR</small>
                                                      {item?.no_pr || "-"}
                                                    </div>
                                                    <div className={"p-0 m-0 d-flex flex-column border-bottom mb-2"}>
                                                      <small className={"text-muted"}>Item PR</small>
                                                      {item?.line_no || "-"}
                                                    </div>
                                                  </>
                                                }
                                                <div className={"p-0 m-0 d-flex flex-column border-bottom mb-2"}>
                                                  <small className={"text-muted"}>Nomor Material</small>
                                                  {item?.material_id || "-"}
                                                </div>
                                                <div className={"p-0 m-0 d-flex flex-column mb-2"}>
                                                  <small className={"text-muted"}>Plant</small>
                                                  {(item?.plant?.kode_plant + ' - ' + item?.plant?.nama_plant) || "-"}
                                                </div>
                                              </div>
                                            </td>
                                            <td className={"border text-start"} colSpan={4}>{`Jasa Item ${item?.line_no}`}</td>
                                            <td className={"border text-start"}>{parseNumberToCurrency(item?.serviceLines?.reduce((a: any, b: any) => parseFloat(a) + parseFloat(b.net_price), 0))}</td>
                                            <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{item?.currency?.code}</td>
                                            <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{parseNumberToCurrency(item?.additional_cost)}</td>
                                            <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{parseNumberToCurrency(parseInt(item?.total_value) + parseInt(item?.additional_cost || '0'))}</td>
                                            <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{item?.delivery_date ? moment(item?.delivery_date).format('DD/MM/yyyy') : ''}</td>
                                            <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{item?.tax_code_id}</td>
                                            <td className={"border text-start"} rowSpan={item?.serviceLines?.length + 1}>{item?.note}</td>
                                          </tr>
                                          {item?.serviceLines?.map((item: any, index: any) => (
                                            <tr>
                                              <td className={"border text-start"}>{item?.short_text}</td>
                                              <td className={"border text-start"}>{item?.quantity}</td>
                                              <td className={"border text-start"}>{item?.uom_id}</td>
                                              <td className={"border text-start"}>{parseNumberToCurrency(item?.gross_price)}</td>
                                              <td className={"border text-start"}>{parseNumberToCurrency(item?.net_price)}</td>
                                            </tr>
                                          ))}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </tbody>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
})

export default HeaderForm