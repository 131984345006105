import { ID, KTCard, KTCardBody } from '../../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TableXWrpper from "../../../../../components/shared/TableXWrapper";
import SelectX from '../../../../../components/shared/form/SelectX'
import { ApiUrl } from '../../../../../config/Api'
import { getEnv } from "../../../../../../helpers/procurex";
import SwalX from '../../../../../components/shared/SwalX'
import { InputNumber } from "rsuite";
import { Field, Form, Formik } from 'formik'
import { useIntl } from 'react-intl'
import { MetronicErrorMessage, toastError } from '../../../../../../_metronic/helpers'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Button, ButtonGroup, Col, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../../../../app/modules/auth";
import DatepickerX2 from "../../../../../components/shared/form/DatepickerX2";
import { ImportExcelModal } from '../../../../../components/shared/import-excel/ImportExcelModal'
import moment from 'moment/moment'

const VesselListStockviewList = () => {
    const intl = useIntl()
    const { currentUser } = useAuth();
    const tablecolumns = [
        {
            Header: "ETA",
            accessor: "eta",
            width: 120,
            Cell: ({ value }) => {
                if (!value) return '';
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            }
        },
        {
            Header: "IMO",
            accessor: "imo",
            width: 80,
        },
        {
            Header: "SHIPNAME",
            accessor: "shipname",
            width: 120,
        },
        {
            Header: "LAT",
            accessor: "lat",
            width: 100,
        },
        {
            Header: "LON",
            accessor: "lon",
            width: 100,
        },
        {
            Header: "DESTINATION",
            accessor: "destination",
            width: 100,
        },
        {
            Header: "FLAG",
            accessor: "flag",
            width: 60,
        },
        {
            Header: "DRAUGHT (m)",
            accessor: "draught",
            width: 100,
            Cell: ({ value }) => {
                return <span>{(value / 10).toFixed(1)} m</span>;
            }
        },
        {
            Header: "TYPE NAME",
            accessor: "type_name",
            width: 100,
        },
        {
            Header: "SHIP COUNTRY",
            accessor: "ship_country",
            width: 100,
        },
        {
            Header: "Create Time",
            accessor: "created_at",
            width: 100,
            Cell: ({ value }) => {
                if (!value) return '';
                const date = new Date(value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                return `${day}-${month}-${year} ${hours}:${minutes}`;
            }
        },
        {
            Header: "Action",
            accessor: "uuid",
            width: 180,
            className: "text-center fixed-column",
            Cell: (props: any) => {
                const row = props.row.original;
                return (
                    <ButtonGroup size='sm' aria-label='Action'>
                        {!excludedRoles.some(r => role.includes(r)) && (
                            <>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-success'
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setId(row.id);
                                        openModalWithItem(row);
                                    }}
                                >
                                    <i className='fa fa-eye' aria-hidden="true"></i> Detail
                                </button>
                            </>
                        )}
                    </ButtonGroup>
                );
            }
        }
    ];

    const excludedRoles = ['admin_anper', 'DaanExp_Anper', 'Gudang_Bahan_Baku_Anper', 'Perencanaan_Pengadaan_Anper', 'Managerial_PI'];
    const [formindex, setFormIndex] = useState(0);

    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    let [itemId, setItemId] = useState(0)
    let [isLoadingDelete, setIsLoadingDelete] = useState(false)
    let [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)
    const [key, setKey] = useState(0)
    const [id, setId] = useState('')
    const [item, setItem] = useState<any>(null)
    const [isConfirmDialog, setIsConfirmDialog] = useState(false)
    const [itemsMasterMaterial, setItemsMasterMaterial] = useState<any>([])
    const [itemsMasterCompany, setItemsCompanyStock] = useState<any>([])
    const [itemLoading, setItemLoading] = useState(false)
    const [isShowImportModal, setIsShowImportModal] = useState(false)

    const loadItem = () => {
        axios
            .get(getEnv("masterdata") + `/master-material`)
            .then((res) => {
                setItemsMasterMaterial(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
        axios
            .get(getEnv("masterdata") + `/company-stock`)
            .then((res) => {
                setItemsCompanyStock(res.data.data)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setItemLoading(false)
            })
    }

    useEffect(() => {
        loadItem();
    }, [])

    const openModalWithItem = async (item: any) => {
        setItem(item);
        setIsShowModal(true);
    };

    let company_code_user = currentUser.company_code;
    let role = currentUser.has_roles;

    return (
        <>
            <Modal size="xl" show={isShowModal} onHide={() => setIsShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Detail Fleet <strong className='font-bold'>{item?.shipname}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-6'>
                            <ul className='list-group'>
                                <li className='list-group-item'><p><strong>IMO:</strong> {item?.imo}</p></li>
                                <li className='list-group-item'><p><strong>MMSI:</strong> {item?.mmsi}</p></li>
                                <li className='list-group-item'><p><strong>Ship ID:</strong> {item?.ship_id}</p></li>
                                <li className='list-group-item'><p><strong>Latitude:</strong> {item?.lat}</p></li>
                                <li className='list-group-item'><p><strong>Longitude:</strong> {item?.lon}</p></li>
                                <li className='list-group-item'><p><strong>ETA:</strong> {item?.eta}</p></li>
                                <li className='list-group-item'><p><strong>Destination:</strong> {item?.destination}</p></li>
                                <li className='list-group-item'><p><strong>Flag:</strong> {item?.flag}</p></li>
                                <li className='list-group-item'><p><strong>Draught:</strong> {item?.draught}</p></li>
                                <li className='list-group-item'><p><strong>Status:</strong> {item?.status}</p></li>
                                <li className='list-group-item'><p><strong>Heading:</strong> {item?.heading}</p></li>
                                <li className='list-group-item'><p><strong>Speed:</strong> {item?.speed}</p></li>
                                <li className='list-group-item'><p><strong>Width:</strong> {item?.width}</p></li>
                            </ul>
                        </div>
                        <div className='col-6'>
                            <ul className='list-group'>
                                <li className='list-group-item'><p><strong>Type Name:</strong> {item?.type_name}</p></li>
                                <li className='list-group-item'><p><strong>Ship Country:</strong> {item?.ship_country}</p></li>
                                <li className='list-group-item'><p><strong>Ship Class:</strong> {item?.ship_class}</p></li>
                                <li className='list-group-item'><p><strong>Last Port:</strong> {item?.last_port}</p></li>
                                <li className='list-group-item'><p><strong>Next Port:</strong> {item?.next_port_name}</p></li>
                                <li className='list-group-item'><p><strong>ETA:</strong> {item?.eta}</p></li>
                                <li className='list-group-item'><p><strong>Destination:</strong> {item?.destination}</p></li>
                                <li className='list-group-item'><p><strong>Flag:</strong> {item?.flag}</p></li>
                                <li className='list-group-item'><p><strong>Length:</strong> {item?.length}</p></li>
                                <li className='list-group-item'><p><strong>Timestamp:</strong> {item?.timestamp}</p></li>
                                <li className='list-group-item'><p><strong>DWT:</strong> {item?.dwt}</p></li>
                                <li className='list-group-item'><p><strong>Year Built:</strong> {item?.year_built}</p></li>
                                <li className='list-group-item'><p><strong>GRT:</strong> {item?.grt}</p></li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <KTCard className='card border mt-5'>
                <KTCardBody >
                    <TableXWrpper
                        title={"Vessel List"}
                        key={formindex}
                        readonly
                        urlIndex={getEnv("stockview") + `/vessel-repository`}
                        columns={tablecolumns}
                    />
                </KTCardBody>
            </KTCard>
        </>
    )
}

export { VesselListStockviewList }
