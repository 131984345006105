import { formatCurrency } from '../../../../../_metronic/helpers'
import { getEnv } from '../../../../../helpers/procurex'
import { Col, Row } from 'react-bootstrap'
import { useState } from 'react'

import TableXWrapper from '../../../../components/shared/TableXWrapper'

const ListPerItem = () => {
  const [key, setKey] = useState(0)

  const tableColumns = [
    { Header: 'No PO', accessor: 'no_po', width: 150, className: 'text-center' },
    { Header: 'Line No PO', accessor: 'line_no_po', width: 100, className: 'text-center' },
    { Header: 'No PR', accessor: 'no_pr', width: 150, className: 'text-center' },
    { Header: 'Line No PR', accessor: 'line_no_pr', width: 100, className: 'text-center' },
    { Header: 'Purch. Org', accessor: 'purchasing_org', width: 110, className: 'text-center' },
    { Header: 'Company', accessor: 'company', width: 100, className: 'text-center' },
    { Header: 'Doc. Date', accessor: 'document_date', width: 150, className: 'text-center' },
    { Header: 'Material Number', accessor: 'material_number', width: 150, className: 'text-center' },
    { Header: 'Description', accessor: 'description', width: 200, className: 'text-center' },
    { Header: 'Qty', accessor: 'qty', width: 150, className: 'text-center' },
    { Header: 'Uom', accessor: 'uom', width: 100, className: 'text-center' },
    { Header: 'Currency', accessor: 'currency', width: 100, className: 'text-center' },
    { Header: 'Per', accessor: 'per', width: 50, className: 'text-center' },
    { Header: 'Vendor', accessor: 'vendor', width: 200, className: 'text-center' },
    { Header: 'Total Price', accessor: 'total_price', width: 150, className: 'text-center' },
    { Header: 'Delivery Date', accessor: 'delivery_date', width: 150, className: 'text-center' },
  ]

  return (
    <div className='card mt-3'>
      <div className='card-body'>
        <Row>
          <Col>
            <TableXWrapper
              readonly
              key={key}
              urlIndex={`${getEnv("masterdata")}/po`}
              columns={tableColumns}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ListPerItem