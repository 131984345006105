import * as Yup from 'yup'
import React, {createContext, useContext, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Field, Form, Formik, FormikConfig} from 'formik'
import {IntlShape, useIntl} from 'react-intl'
import {useQueryClient} from 'react-query'
import {AxiosError} from 'axios'
import {EmployeeModel, UserModel} from '../../../../../../auth'
import {
  MetronicErrorMessage,
  toastError,
  toastSuccess,
} from '../../../../../../../../_metronic/helpers'
import {
  useGetRoutingApprovalConfigForSubmitBuyerQuery,
  useSubmitBuyerMutation,
} from '../../../../evaluation/core/_queries'
import {ApprovalX} from '../../../../../../../components/shared/ApprovalX'
import {useUploadMedia} from '../../../../../../../../hooks/MediaUploadHook'
import {EvaluasiUserTabContext} from './EvaluasiUserTab'
import FormikUploadFile from '../../../../../../../components/shared/form/FormikUploadFile'
import Select from 'react-select'
import {getSelectStylesControl} from '../../../../../../../components/shared/form/SelectX'
import FormikSelect2 from '../../../../../../../components/shared/form/FormikSelect2'
import {getEnv} from '../../../../../../../../helpers/procurex'
import {MediaModel} from '../../../../../../../../_models'
import {
  ClarificationTenderItemParameterObject,
  ProposalTenderItemModel,
} from '../../../../../core/_models'

type EvaluationSendToEvaluatorModalContextType = {
  categories: string[]
  parameters: ClarificationTenderItemParameterObject[]
  buyer_note: string
  evaluator: UserModel | null
  attachments: Array<File | null>
}

const EvaluationSendToEvaluatorModalContext =
  createContext<EvaluationSendToEvaluatorModalContextType>({
    categories: [],
    parameters: [{name: '', category: ''}],
    buyer_note: '',
    evaluator: null,
    attachments: [],
  })

type ApprovalModalProps = {
  show: boolean
  onHide: () => void
  onSuccess: () => void
}

const ApprovalModal = ({show, onHide, onSuccess}: ApprovalModalProps) => {
  const queryClient = useQueryClient()
  const evaluasiUserTabContext = useContext(EvaluasiUserTabContext)
  const sendToEvaluatorModalContext = useContext(EvaluationSendToEvaluatorModalContext)
  const {upload} = useUploadMedia()
  const {mutate: submit, isLoading: isLoadingSubmitForApproval} = useSubmitBuyerMutation({})

  const proposalTenderItemId = evaluasiUserTabContext.proposalTender.uuid
  const proposalTenderItemIds = evaluasiUserTabContext.selectedProposalTenderItems.map(
    (item: ProposalTenderItemModel) => item.uuid
  )
  const isGetRoutingApprovalConfigQueryEnabled =
    show && !isLoadingSubmitForApproval && proposalTenderItemId && proposalTenderItemIds.length > 0

  const {data: routingApprovalConfig, isFetching: isFetchingRoutingApprovalConfig} =
    useGetRoutingApprovalConfigForSubmitBuyerQuery(proposalTenderItemId, proposalTenderItemIds, {
      enabled: isGetRoutingApprovalConfigQueryEnabled,
      retry: false,
      onError: (error: AxiosError) => {
        toastError(
          error.response.data?.meta?.message || error.response.data?.message || 'Terjadi kesalahan'
        )
        onHide()
      },
    })

  return (
    <ApprovalX
      show={show}
      onHide={onHide}
      readOnly={false}
      isLoading={isLoadingSubmitForApproval || isFetchingRoutingApprovalConfig}
      schema={routingApprovalConfig?.details}
      onSubmit={async (values: {approval: any[]}) => {
        const {categories, evaluator, attachments, ...others} = sendToEvaluatorModalContext
        const PR_TENDER_OTHERS_MEDIA_TYPE_ID = '25'
        const attachmentList = attachments ?? []
        const medias: Array<MediaModel | null> = await Promise.all(
          attachmentList.map(async (attachment) => {
            if (attachment) {
              return await upload(PR_TENDER_OTHERS_MEDIA_TYPE_ID, attachment)
            }
            return null
          })
        )

        const payload = {
          ...others,
          proposal_tender_id: proposalTenderItemId,
          proposal_tender_item_ids: proposalTenderItemIds,
          evaluator_id: evaluator?.id,
          attachment_urls: medias.map((media) => media?.url || ''),
          approvers: values.approval.map((item: any) => ({
            step: item.step,
            jabatan: item.jabatan,
            type: 'APPROVAL',
            user_id: item.extra_info.user.user_id,
            extra_info: {},
          })),
        }

        submit(payload, {
          onSuccess: async (response) => {
            await queryClient.invalidateQueries([
              'proposal-tender-items',
              {proposal_tender_id: proposalTenderItemId},
            ])

            await queryClient.invalidateQueries([
              'clarification-tender-items',
              'evaluator',
              'routing-approval-config',
            ])

            toastSuccess(response.data.meta.message)
            onSuccess()
          },
          onError: (error: AxiosError) => {
            toastError(
              error.response.data?.meta?.message ||
                error.response.data?.message ||
                'Terjadi kesalahan'
            )
          },
        })
      }}
    />
  )
}

function getCategoryOptions(intl: IntlShape, metode_penyampaian_step: number | null) {
  const administrationOption = {
    value: 'administration',
    label: intl.formatMessage({id: 'Administrasi'}),
  }
  const technicalOption = {
    value: 'technical',
    label: intl.formatMessage({id: 'Teknis'}),
  }
  const commercialOption = {
    value: 'commercial',
    label: intl.formatMessage({id: 'Komersil'}),
  }

  switch (metode_penyampaian_step) {
    case 1:
      return [administrationOption, technicalOption]
    case 2:
      return [commercialOption]
    default:
      return [administrationOption, technicalOption, commercialOption]
  }
}

type MainModalProps = {
  show: boolean
  onHide?: () => void
  onSuccess?: () => void
  onSubmit: FormikConfig<any>['onSubmit']
}

const MainModal = ({show, onHide, onSubmit}: MainModalProps) => {
  const intl = useIntl()
  const evaluasiUserTabContext = useContext(EvaluasiUserTabContext)
  const proposalTenderItems = evaluasiUserTabContext.selectedProposalTenderItems
  const categoryOptions = getCategoryOptions(
    intl,
    evaluasiUserTabContext.proposalTender.metode_penyampaian_step
  )

  const defaultParameter = {name: '', category: ''}

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        parameters: [{name: '', category: ''}],
        buyer_note: '',
        attachment: '',
        evaluator: '',
      }}
      validationSchema={Yup.object().shape({
        parameters: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string()
                .required()
                .label(intl?.formatMessage({id: 'Parameter Evaluasi'})),
              category: Yup.string()
                .required()
                .label(intl?.formatMessage({id: 'Kategori Parameter'})),
            })
          )
          .required()
          .min(1),
        buyer_note: Yup.string()
          .required()
          .label(intl.formatMessage({id: 'Note dari Buyer'})),
        evaluator: Yup.object()
          .required()
          .label(intl.formatMessage({id: 'Tujuan Unit Kerja'})),
      })}
    >
      {({values, setFieldValue}) => (
        <Modal size='lg' show={show} onHide={onHide}>
          <Form>
            <Modal.Header closeButton>
              <h4>{intl.formatMessage({id: 'List Item'})}</h4>
            </Modal.Header>
            <Modal.Body>
              {/*region Proposal Tender Items Checklist*/}
              <div className='mb-4'>
                <h5>{intl.formatMessage({id: 'Item PR untuk di Evaluasi'})}</h5>

                <table className={'table table-bordered table-striped table-header-gray mb-0'}>
                  <thead>
                    <tr>
                      <th className='px-2 border text-center' style={{width: 50}}>
                        {intl?.formatMessage({id: 'No'})}
                      </th>
                      <th className='px-2 border'>{intl?.formatMessage({id: 'Nomor PR'})}</th>
                      <th className='px-2 border'>{intl?.formatMessage({id: 'Item PR'})}</th>
                      <th className='px-2 border'>{intl?.formatMessage({id: 'Short Text'})}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposalTenderItems.length > 0 &&
                      proposalTenderItems.map((item: any, index: any) => (
                        <tr key={index}>
                          <td className='px-2 border text-center'>{index + 1}</td>
                          <td className='px-2 border'>{item.no_pr}</td>
                          <td className='px-2 border'>{item.line_no}</td>
                          <td className='px-2 border'>{item.short_text}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <MetronicErrorMessage name='proposal_tender_item_ids' />
              </div>
              {/*endregion*/}

              {/*region Buyer Note*/}
              <div className='mb-4'>
                <label className='form-label mb-3 required' htmlFor='buyer_note'>
                  {intl.formatMessage({id: 'Note dari Buyer'})}
                </label>

                <Field as='textarea' name='buyer_note' className={'form-control'} />

                <MetronicErrorMessage name='buyer_note' />
              </div>
              {/*endregion*/}

              {/*region Attachment*/}
              <div className='mb-4'>
                <FormikUploadFile
                  label={intl.formatMessage({id: 'Attachment'})}
                  name='attachments'
                  multiple
                />
              </div>
              {/*endregion*/}

              {/*region Categories Select*/}
              {/*<div className='mb-4'>*/}
              {/*  <label className='form-label mb-3 required' htmlFor='categories'>*/}
              {/*    {intl.formatMessage({id: 'Kategori Evaluasi'})}*/}
              {/*  </label>*/}

              {/*  <Select*/}
              {/*    isMulti*/}
              {/*    name='categories'*/}
              {/*    options={categoryOptions}*/}
              {/*    styles={{*/}
              {/*      control: (baseStyles, props) => getSelectStylesControl(baseStyles, props),*/}
              {/*    }}*/}
              {/*    defaultValue={categoryOptions.filter((option) =>*/}
              {/*      values.categories.includes(option.value)*/}
              {/*    )}*/}
              {/*    onChange={(newValue) =>*/}
              {/*      setFieldValue(*/}
              {/*        'categories',*/}
              {/*        newValue.map((option: any) => option.value)*/}
              {/*      )*/}
              {/*    }*/}
              {/*  />*/}

              {/*  <MetronicErrorMessage name='categories' />*/}
              {/*</div>*/}
              {/*endregion*/}

              <hr />

              {/*region Parameters*/}
              <div className='mb-4'>
                <div className='row align-items-center mb-4'>
                  <div className='col'>
                    <h5 className='mb-0'>
                      <label className='form-label mb-0 required' htmlFor='parameters.0'>
                        {intl.formatMessage({id: 'Evaluation Parameters'})}
                      </label>
                    </h5>
                  </div>
                  <div className='col-auto'>
                    <Button
                      type='button'
                      variant='light'
                      size='sm'
                      onClick={() => {
                        setFieldValue('parameters', values.parameters.concat({...defaultParameter}))
                      }}
                    >
                      {intl.formatMessage({id: 'Tambah Parameter'})}
                    </Button>
                  </div>
                </div>

                <table className={'table table-bordered table-striped table-header-gray mb-0'}>
                  <thead>
                    <tr>
                      <th className='px-2 border text-center' style={{width: 50}}>
                        {intl?.formatMessage({id: 'No'})}
                      </th>
                      <th className='px-2 border'>
                        {intl?.formatMessage({id: 'Parameter Evaluasi'})}
                      </th>
                      <th className='px-2 border'>
                        {intl?.formatMessage({id: 'Kategori Parameter'})}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.parameters.map(
                      (parameter: ClarificationTenderItemParameterObject, index: number) => (
                        <tr key={index}>
                          <td className='px-2 border text-center align-middle'>
                            <span style={{padding: '0.55rem 0.75rem'}}>{index + 1}</span>
                          </td>
                          <td className='p-2 border'>
                            <Field
                              name={`parameters.${index}.name`}
                              className='form-control form-control-sm'
                              style={{
                                fontSize: '13px',
                                paddingTop: '0.825rem',
                                paddingBottom: '0.825rem',
                              }}
                            />

                            <MetronicErrorMessage name={`parameters.${index}.name`} />
                          </td>
                          <td className='p-2 border'>
                            <Select
                              name={`parameters.${index}.category`}
                              options={categoryOptions}
                              styles={{
                                control: (baseStyles, props) =>
                                  getSelectStylesControl(baseStyles, props),
                              }}
                              defaultValue={categoryOptions.filter((option) => {
                                return parameter.category === option.value
                              })}
                              onChange={(newValue) => {
                                console.log(newValue)
                                setFieldValue(`parameters.${index}.category`, newValue.value)
                              }}
                            />

                            <MetronicErrorMessage name='categories' />
                          </td>
                          <td>
                            {values.parameters.length > 1 && (
                              <button
                                type='button'
                                className='btn btn-sm btn-outline-danger'
                                onClick={() => {
                                  setFieldValue(
                                    'parameters',
                                    values.parameters
                                      .slice(0, index)
                                      .concat(values.parameters.slice(index + 1))
                                  )
                                }}
                              >
                                <i className='fa fa-trash' />
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              {/*endregion*/}

              <hr />

              {/*region Tujuan Unit Kerja*/}
              <div>
                <label className='form-label required' htmlFor='evaluator'>
                  {intl.formatMessage({id: 'Tujuan Unit Kerja'})}
                </label>

                <FormikSelect2
                  name='evaluator'
                  optionsUrl={`${getEnv('sso')}/user?filter[not_vendor]=1`}
                  getOptionLabel={(opt: UserModel) => {
                    const employee: EmployeeModel | null | undefined = opt.employee

                    return `${employee?.dept_title || '-'} (${opt.username} - ${opt.name})`
                  }}
                  getOptionValue={(opt: any) => opt}
                  unique_property='id'
                  search_property={'dept_title_username_name'}
                />
              </div>
              {/*endregion*/}
            </Modal.Body>
            <Modal.Footer>
              <Button type='button' variant='danger' onClick={onHide}>
                {intl.formatMessage({id: 'Batal'})}
              </Button>
              <Button type='submit' variant='secondary'>
                {intl.formatMessage({id: 'Submit'})}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

interface EvaluationSendToEvaluatorModalProps {
  proposalTenderItems: ProposalTenderItemModel[]
  show: boolean
  onHide: () => void
  onSuccess?: () => void
}

const EvaluationSendToEvaluatorModal = ({
  show,
  onHide,
  onSuccess,
}: EvaluationSendToEvaluatorModalProps) => {
  const [isShowMainModal, setIsShowMainModal] = useState(show)
  const [isShowApprovalModal, setIsShowApprovalModal] = useState(false)
  const [contextValue, setContextValue] = useState<EvaluationSendToEvaluatorModalContextType>({
    categories: [],
    parameters: [],
    buyer_note: '',
    evaluator: null,
    attachments: null,
  })

  useEffect(() => {
    setIsShowMainModal(show)
    setIsShowApprovalModal(false)
  }, [show])

  const onHideFn = () => {
    setIsShowApprovalModal(false)
    setIsShowMainModal(false)
    onHide()
  }

  return (
    <EvaluationSendToEvaluatorModalContext.Provider value={contextValue}>
      <MainModal
        show={isShowMainModal}
        onHide={onHideFn}
        onSubmit={(values) => {
          setContextValue((current) => ({...current, ...values}))
          setIsShowMainModal(false)
          setIsShowApprovalModal(true)
        }}
      />

      <ApprovalModal
        show={isShowApprovalModal}
        onHide={onHideFn}
        onSuccess={() => {
          onHideFn()
          onSuccess && onSuccess()
        }}
      />
    </EvaluationSendToEvaluatorModalContext.Provider>
  )
}

export default EvaluationSendToEvaluatorModal
