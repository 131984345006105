import {Modal, Spinner} from 'react-bootstrap'
import React, {Fragment, useEffect, useState} from 'react'
import { Field, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import FormikSelect2 from "./form/FormikSelect2";
import { useAuth } from "../../modules/auth";
import axios from "axios";
import {formatDateTimeToDBFormat, getEnv} from '../../../helpers/procurex'
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useGetSuperiorEmployee } from "./core/hook";
import { useIntl } from "react-intl";
import {useMutation} from 'react-query'

interface IFormApproveReject {
  show: boolean,
  onHide: any,
  type: string | "APPROVE" | "REJECT",
  source: string
  object_id: string | number
  detail_approve_id: number
  company_id: number
  code: string
  onSubmit?: any
  fetchDetail?: any
}

const FormApproveReject = ({
                             show,
                             onHide,
                             type,
                             source,
                             object_id,
                             company_id,
                             detail_approve_id,
                             code,
                             onSubmit,
                             fetchDetail
                           }: IFormApproveReject) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const validationSchema = Yup.object({
    // comment: Yup.string().required("Komentar harus diisi")
  });
  const intl = useIntl();
  const handleOnSubmit = async (values: any) => {
    if(!values?.comment && type === "REJECT") {
      toast.error("Please fill comment first");
      return ;
    }
    const payload = {
      company_id: company_id,
      object_id: object_id,
      source: source,
      code: code,
      detail_id: detail_approve_id,
      detail_action: type,
      comment: values.comment || "-"
    };
    Swal.fire({
      title: `${intl.formatMessage({id:"Apakah Anda yakin"})}?`,
      html: `${intl.formatMessage({id:"Anda akan melakukan aksi"})} <strong>${type}</strong>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Ya, ${type}`,
      cancelButtonText: intl.formatMessage({id:"Cancel"}),
      confirmButtonColor: type === "APPROVE" ? "#3085d6" : "#d33"
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Processing...',
          html: `
        <div class="custom-loading-spinner">
           <img src='http://localhost:3011/pi2.gif' width="75" alt="Loading" />
        </div>
      `,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.getHtmlContainer().style.overflow = 'hidden';
          },
        });
        setIsLoading(true);
        axios.patch(getEnv("approval") + "/routing-approval-detail/status", payload)
          .then((res) => {
            setTimeout(()=>{
              Swal.close();
              toast.success(res.data.meta.message);
              onSubmit && onSubmit();
              fetchDetail()
            },3000)
          }).catch((err) => {
          Swal.fire(
            err.response.data.message || err.response.data.meta.message,
            "",
            "error"
          );
        }).finally(() => {
          setIsLoading(false);
          onHide();
        });
      }
    });
  };
  return (
    <Modal size="lg" keyboard={!isLoading} show={show} onHide={() => isLoading ? null : onHide()}>
      <Formik initialValues={{
        comment: ""
      }} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
        {({ errors, touched, values, setFieldValue }) => (
          <>
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{type === "APPROVE" ? "Approve" : "Reject"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-10">
                  <label htmlFor="comment mb-3 form-label">
                    {intl.formatMessage({ id: "Komentar" })}
                  </label>
                  <Field as="textarea" name="comment" id="comment" className="form-control" />
                  {errors.comment && touched.comment && (
                    <div className="fv-plugins-message-container">
                      {/* @ts-ignore*/}
                      <div className="fv-help-block">{errors?.comment}</div>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => isLoading ? null : onHide(false)}
                  className="btn btn-secondary btn-sm"
                  type="button"
                  disabled={isLoading}
                >
                  <i className={"fa fa-times"}></i>
                  Cancel
                </button>
                <button
                  className={`btn ${type === "APPROVE" ? "btn-success" : "btn-danger"} btn-sm`}
                  type="submit"
                  disabled={isLoading}
                >
                  {type === "APPROVE" && <i className="fa fa-check me-2"></i>}
                  {type === "REJECT" && <i className="fa fa-times me-2"></i>}
                  {isLoading && <i className="fa fa-spinner fa-spin me-2"></i>}
                  {type === "APPROVE" ? "Approve" : "Reject"}
                </button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};

const ButtonProsesApproval = ({
                                isLoading,
                                onShowDetailApproval,
                                source,
                                object_id,
                                company_id,
                                code,
                                onSubmit
                              }: any) => {
  const { currentUser } = useAuth();
  const [isCanApprove, setIsCanApprove] = useState<boolean>(false);
  const [isCanReject, setIsCanReject] = useState<boolean>(false);
  const [typeApprove, setTypeApprove] = useState<string>("APPROVE");
  const [isShowModalApprove, setIsShowModalApprove] = useState<boolean>(false);
  const [detailApprovalId, setDetailApprovalId] = useState<number>(0);
  const [isShowApprovalSchema, setIsShowApprovalSchema] = useState<boolean>(false);
  const [routingApprovalSchema, setRoutingApprovalSchema] = useState<any>([]);

  const fetchDetail = () => {
    axios.get(getEnv("approval") + "/routing-approval/detail", {
      params: {
        company_id: company_id,
        object_id: object_id,
        source: source,
        code: code
      }
    }).then((res) => {
      if (res.data.data[0].current?.user_id === currentUser?.id) {
        setIsCanApprove(true);
        setIsCanReject(true);
        setDetailApprovalId(res.data.data[0]?.current.id);
      } else {
        setIsCanApprove(false);
        setIsCanReject(false);
      }
    });
  }

  useEffect(() => {
    fetchDetail()
  }, []);

  const {mutate, isLoading: isDetailLoading } = useMutation({
    mutationKey: ["getDetailApproval",company_id, object_id, source, code],
    mutationFn: ({ company_id, object_id, source, code }:any) => axios.get(getEnv("approval")+"/routing-approval/detail", {
      params: {
        company_id: company_id,
        object_id: object_id,
        source: source,
        code: code
      }
    }),
    onSuccess: (data) => {
      setIsShowApprovalSchema(true)
      setRoutingApprovalSchema(data.data.details);
    },
    onError: (error) => {
      console.error(error);
    }
  })
  const handleOnShowDetailApproval = () => {
    mutate({ company_id, object_id, source, code });
  };
  return (
    <>
      {isCanReject && (
        <button type='button' disabled={isShowModalApprove} className={"btn btn-sm btn-danger me-3"} onClick={() => {
          setTypeApprove("REJECT");
          setIsShowModalApprove(true);
        }}>
          {isShowModalApprove && (
            <Spinner animation={'border'} color={'white'} style={{color: 'white'}} size={"sm"} className={"me-2"} />
          )}
          {!isShowModalApprove && (
            <i className={'fa fa-times'}></i>
          )}
          Reject
        </button>
      )}
      {isCanApprove && (
        <button type='button' className={"btn btn-sm btn-success me-3"} onClick={() => {
          setTypeApprove("APPROVE");
          setIsShowModalApprove(true);
        }}>
          {isShowModalApprove && (
            <Spinner animation={'border'} color={'white'} style={{color: 'white'}} size={"sm"} className={"me-2"} />
          )}
          {!isShowModalApprove && (
            <i className={'fa fa-check'}></i>
      )}

      Approve
    </button>
      )}

      <button
        type='button'
        className={"btn btn-sm btn-primary me-3"}
        onClick={() => handleOnShowDetailApproval()}
        disabled={isLoading || isDetailLoading}
      >
        {isDetailLoading ? <i className="fa fa-spinner fa-spin me-2"></i> : <i className={'fa fa-list'}></i>}
        Approval
      </button>

      <FormApproveReject
        type={typeApprove}
        show={isShowModalApprove}
        source={source}
        object_id={object_id}
        company_id={company_id}
        code={code}
        onHide={() => {
          setIsShowModalApprove(false);
        }}
        detail_approve_id={detailApprovalId}
        onSubmit={() => {
          onSubmit && onSubmit();
        }}
        fetchDetail={() => {
          fetchDetail();
        }}
      />

      <ApprovalX
        show={isShowApprovalSchema}
        schema={routingApprovalSchema}
        onHide={() => {
          setIsShowApprovalSchema(false)
        }}
        isLoading={isLoading}
        source={source}
        object_id={object_id}
        company_id={company_id}
        code={code}
        readOnly={true}
      />
    </>
  );
};


const StatusBadge = ({ currentApproval, status, approvalId }: {
  currentApproval: any,
  status: string;
  approvalId: string | number
}) => {
  if (status === "OPEN") {
    return (
      <span
        className={`badge badge-lg ${currentApproval?.id === approvalId ? "badge-warning text-dark" : "badge-info text-white"}`}>
        {currentApproval?.id === approvalId ? "Ongoing" : "Waiting"}
      </span>
    );
  } else if (status === "DONE") {
    return <span className="badge badge-lg badge-success">Done</span>;
  }

  // @TODO: Check for latest action (approve / reject)
  return <></>;
};

const TrApproval = ({
                      index,
                      item,
                      remove,
                      reorderUp,
                      reorderDown,
                      disabled,
                      isFirstItem,
                      isLastItem,
                      isLoading,
                      currentApproval,
                      formikRef
                    }: any) => {
  const { currentUser } = useAuth();
  const [selectedUser, setSelectedUser] = useState<any>();
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  const [defaultUser, setDefaultUser] = useState<any>(null);
  const [selectIndex, setSelectIndex] = useState(0);
  const [disabledUserPlt, setDisabledUserPlt] = useState(true);
  const [jabatanApprover, setJabatanApprover] = useState("");
  const [
    isLoadingSuperiorEmployee,
    responseSuperiorEmployee,
    responseErrorSuperiorEmployee,
    getSuperiorEmployee
  ] = useGetSuperiorEmployee();
  const [superiorEmployee, setSuperiorEmployee] = useState(null);

  const loadUser = async () => {
    if(item?.user_id) {
      axios.get(getEnv("sso") + `/user/${item?.user_id}`).then((res) => {
        setIsLoadingUser(false);
        setSelectedUser(res.data.data);
        setSelectIndex(selectIndex + 1)
      });
    }
  }

  useEffect(() => {
    if (disabled && item?.user_id) {
      setIsLoadingUser(true);
      loadUser()

      console.log('item', item?.extra_info?.user?.pos_title)
      if ((item?.extra_info?.user?.pos_title) && (item?.extra_info !== 'no extra info')) {
        setJabatanApprover(item?.extra_info?.user?.pos_title)
      } else if (item?.extra_info !== 'no extra info') {
        setJabatanApprover(JSON.parse(item?.extra_info)?.user?.pos_title)
      }
    }
  }, [disabled, item, item?.user_id]);

  useEffect(() => {
    if (item.extra_info.superior_filter) {
      axios.get(getEnv("sso") + "/employee/superior", {
        params: {
          level: item.extra_info.superior_filter
        }
      }).then((res) => {
        // console.log(res.data.data[0]);
        formikRef.current.setFieldValue(`details.${index}.extra_info.user_id`, res?.data?.data[0]?.user_id);
        formikRef.current.setFieldValue(`details.${index}.extra_info.user`, res?.data?.data[0]);
        formikRef.current.setFieldValue(`details.${index}.extra_info.user_plt`, res?.data?.data[0]);
        setSelectIndex(selectIndex + 1);
      });
    }
    loadUser()
  }, []);


  // useEffect(() => {
  //   if(responseSuperiorEmployee){
  //     if(responseSuperiorEmployee?.data && responseSuperiorEmployee?.data.length > 0){
  //       setFieldValue(`${index}.extra_info.user`,responseSuperiorEmployee?.data[0])
  //     }
  //   }
  // },[responseSuperiorEmployee])
  return (
    <tr>
      <td className={"text-center pt-5"} style={
        {verticalAlign:'middle'}
      }>
      {index + 1}
      </td>

      {!disabled && (
        <>
          <td>
            <FormikSelect2
              key={selectIndex}
              name={`details.${index}.extra_info.user`}
              optionsUrl={
                `${getEnv("masterdata")}/employee?` +
                (item?.extra_info?.emp_grade
                  ? `filter[emp_grade]=${item?.extra_info?.emp_grade}&filter[company]=${currentUser?.company_code}`
                  : "")
              }
              getOptionLabel={(opt: any) =>
                `${opt.emp_no} - ${opt.nama}`
              }
              getOptionValue={(opt: any) => opt}
              required={true}
              unique_property="emp_no"
              search_property={"emp_no_nama"}
              width={"240px"}
              isDisabled={isLoading || disabled}
              defaultValue={defaultUser}
              onChangeValue={(e: any) => {
                // console.log(e)
                formikRef.current.setFieldValue(`details.${index}.extra_info.user_plt`, e);
              }}
            />
          </td>
        </>
      )}
      {disabled && selectedUser && (
        <>
          <td className="text-center">
            {isLoadingUser && (
              <i className="fa fa-spinner fa-spin"></i>
            )}
            {selectedUser?.username} <br/> {selectedUser?.name}
          </td>
        </>
      )}

      <td className="text-center">
        {item.extra_info?.jabatan_description ||
          item.jabatan ||
          item.extra_info.user?.pos_kategori}
      </td>

      {!disabled && (
        <td style={{
          verticalAlign: 'middle',
        }}>
          <FormikSelect2
            key={selectIndex}
            name={`details.${index}.extra_info.user_plt`}
            optionsUrl={
              `${getEnv('masterdata')}/employee-wum?` +
              (item?.extra_info?.user?.emp_no
                ? `filter[emp_no_nama]=${item?.extra_info?.user?.emp_no}`
                : '')
            }
            getOptionLabel={(opt: any) =>
              `${opt?.pos_title}`
            }
            getOptionValue={(opt: any) => opt}
            required={false}
            search_property={'pos_title'}
            width={'240px'}
            isDisabled={isLoading}
            defaultValue={defaultUser}
            onChangeValue={(e: any) => {
              {
                item?.extra_info?.user &&
                formikRef.current.setFieldValue(`details.${index}.extra_info.user.pos_title`, e?.pos_title)
              }
            }}
          />
        </td>
      )}

      {disabled && (
        <td style={{
          verticalAlign: 'middle',
        }} className="text-center">
            {jabatanApprover}
        </td>
      )}
      <td className={'text-center pt-5'} style={{verticalAlign:'middle'}}>
        {!disabled && (
          <div className={"btn-group btn-group-sm"}>
            <button type={"button"} className="btn btn-icon btn-sm btn-active-secondary me-1"
                    disabled={isFirstItem || isLoading || disabled} onClick={reorderUp}>
              <i className="fa fa-arrow-up"></i>
            </button>
            <button type={"button"} className="btn btn-icon btn-sm btn-active-secondary me-1"
                    disabled={isLastItem || isLoading || disabled}
                    onClick={reorderDown}>
              <i className="fa fa-arrow-down"></i>
            </button>
            {!item.jabatan && (
              <button type={"button"} className="btn btn-icon btn-sm btn-light-danger" onClick={() => {
                remove(index);
              }}
                      disabled={isLoading || disabled}>
                <i className="fa fa-trash text-danger"></i>
              </button>
            )}
          </div>
        )}
        {disabled && (
          <>
            <StatusBadge
              status={item.status}
              approvalId={item.id}
              currentApproval={currentApproval}
            />
          </>
        )}
      </td>
      {disabled && (
        <td className={"text-nowrap text-center"}>
          {item?.status === "DONE" && (
           <>{formatDateTimeToDBFormat(item?.updated_at)}</>
          )}

        </td>
      )}

    </tr>
  );
};

type Direction = "up" | "down"
const ApprovalX = ({
                     show,
                     onHide,
                     schema,
                     onSubmit,
                     isLoading,
                     source,
                     object_id,
                     company_id,
                     code,
                     title,
                      titleOverride=false,
                     AdditionalForm,
                     readOnly = false,
                     headerInfo = null
                   }: any) => {
  const [approval, setApproval] = useState<any>({
    details: []
  });
  const [currentApproval, setCurrentApproval] = useState<any>();
  const validationSchema = Yup.object({});
  const formikRef = React.useRef<any>();
  const [parentIndex, setParentIndex] = useState(0);
  const [additionalForm, setAdditionalForm] = useState<any>(null as any);
  const [tableKey, setTableKey] = useState(0);
  const [formKey,setFormKey] = useState(0)
  const intl = useIntl();
  const handleOnSubmit = async (values: any) => {
    let payload = {
      approval: values.details
    };
    payload.approval.map((v:any)=>{
      if(!v.type) v.type = 'APPROVAL'
      return v;
    })
    if (additionalForm) {
      payload = {
        ...payload,
        ...additionalForm
      };
    }
    // console.log(payload);
    onSubmit(payload);
  };

  const reorderApproval = (details: Array<any>, oldIndex: number, direction: Direction) => {
    const newIndex = direction === 'up' ? oldIndex - 1 : oldIndex + 1
    const movedItem = details.find((item, index) => index === oldIndex)
    const remainingItems = details.filter((item, index) => index !== oldIndex)

    const newDetails = [
      ...remainingItems.slice(0, newIndex),
      movedItem,
      ...remainingItems.slice(newIndex),
    ]

    setApproval({...approval, details: newDetails})
  }
  useEffect(() => {
    if (schema) {
      let data: any = [];
      schema?.forEach((item: any) => {
        data.push({
          step: item.step,
          jabatan: item.jabatan,
          type: item.type,
          extra_info: item.extra_info
        });
      });
      setApproval({
        details: data
      });
    }
  }, [schema]);

  useEffect(() => {
    if (readOnly) {
      axios.get(getEnv("approval") + "/routing-approval/detail", {
        params: {
          company_id: company_id,
          object_id: object_id,
          source: source,
          code: code
        }
      }).then((res) => {
        setApproval({
          details: res.data.data[0].details
        });
        setCurrentApproval(res.data.data[0].current);
        setFormKey(formKey+1)
      });
    }
  }, [readOnly, company_id, object_id, source, code]);

  const handleAddApprovers = (values: any) => {
    let tempApprover = values
    let data = {
      step: 1,
      jabatan: "",
      type: "",
      extra_info: {
        user: "",
        is_plt: false
      }
    };
    setApproval((prev:any) => [...prev, data]);
  };

  const handleRemoveList = (index: number) => {
    const newDetails = formikRef.current.values.filter((item: any, i: number) => i !== index);
    setApproval(newDetails);
  };

  // useEffect(() => {
  //   setTableKey(tableKey + 1);
  // }, [approval]);

  return (
    <>
    {Array.isArray(approval.details) && (
      <Formik
        innerRef={formikRef}
        initialValues={approval}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {({ errors, touched, values, setFieldValue }) => (
          <>
            <Modal size="lg" keyboard={isLoading ? false : true} show={show} onHide={() => isLoading ? null : onHide()}
                   backdrop={isLoading ? "static" : true}
                   style={{
                     zIndex: 2000
                   }}
            >
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {!titleOverride && "Approval"} {title}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {headerInfo}
                  {AdditionalForm && <AdditionalForm onChange={(value: string, field: string) => {
                    setAdditionalForm({
                      ...additionalForm,
                      [field]: value
                    });
                  }} />}



                  <FieldArray name="details">
                    {({ push, remove }) => (
                      <div>
                        {!readOnly && (
                          <button className={"btn btn-primary btn-sm mb-4"} disabled={isLoading} type={"button"}
                                  onClick={() => {
                                    push({
                                      user_id: null,
                                      step: values.details.length + 1,
                                      jabatan: null,
                                      type: 'APPROVAL',
                                      extra_info: {
                                        emp_grade: '',
                                        is_plt: false,
                                        is_deleteable: true,
                                      },
                                    })
                                  }}>
                            <i className={"fa fa-plus"}></i> {intl.formatMessage({id: "Tambah Approver"})}
                          </button>
                        )}
                        <div className={"table-responsive"} key={"table-key" + tableKey}>
                          <table className="table table-sm table-hover table-sm align-middle">
                            <thead>
                            <tr>
                              <td className="text-center pt-5" width={10}>
                                <strong>{intl.formatMessage({id: "No"})}</strong></td>
                              <td className="text-center pt-5" width={200}>
                                <strong>{intl.formatMessage({id: "Nama"})}</strong></td>
                              <td className="text-center pt-5" width={200}>
                                <strong>{intl.formatMessage({id: "Jabatan"})}</strong></td>
                              <td className="text-center pt-5" width={200}>
                                <strong>{intl.formatMessage({id: "Jabatan Approve"})}</strong></td>
                              <td className="text-center pt-5" width={200}>
                                <strong>{readOnly ? "Status" : intl.formatMessage({id: "Aksi"})}</strong>
                              </td>
                              {readOnly && (
                                <td className={"text-nowrap text-center"}>
                                  <strong>{intl.formatMessage({id: "Approve At"})}</strong>
                                </td>
                              )}
                            </tr>
                            </thead>
                            <tbody>
                            {values.details.map((item: any, index: number) => (
                              <Fragment key={index}>
                                <TrApproval
                                  index={index}
                                  item={item}
                                  isFirstItem={index === 0}
                                  isLastItem={index === values.details.length - 1}
                                  reorderUp={() => reorderApproval(values.details, index, "up")}
                                  reorderDown={() => reorderApproval(values.details, index, "down")}
                                  remove={() => {
                                    remove(index);
                                  }}
                                  isLoading={isLoading}
                                  disabled={readOnly}
                                  currentApproval={currentApproval}
                                  setFieldValue={setFieldValue}
                                  formikRef={formikRef}
                                />
                              </Fragment>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                    )}
                  </FieldArray>
                </Modal.Body>
                <Modal.Footer>
                  <button className={"btn btn-danger btn-sm"} disabled={isLoading} type={"button"}
                          onClick={() => isLoading ? null : onHide()}>
                    <i className={"fa fa-times"}></i> {intl.formatMessage({id: "Tutup"})}
                  </button>
                  {!readOnly && (
                    <button className={"btn btn-primary btn-sm"} type={"submit"} disabled={isLoading}>
                      {isLoading && <i className={"fa fa-spinner fa-spin me-2"}></i>}
                      {!isLoading && <i className={"fa fa-save me-2"}></i>}
                      {intl.formatMessage({id: "Submit"})}
                    </button>
                  )}
                </Modal.Footer>
              </Form>
            </Modal>
          </>
        )}
      </Formik>
      )}
    </>
  );
};


interface IButtonDetailApprovalOnTable {
  title?: string;
  source: string;
  object_id: string | number;
  company_id: number;
  code?: string;
}

const ButtonDetailApprovalOnTable = ({title, source, object_id, company_id, code}: IButtonDetailApprovalOnTable) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [schema, setSchema] = useState<any>([]);
  return (
    <>
      {code && (
        <>
          <a href={"#"} title={"Detail Approval"} onClick={(e: any) => {
            e.preventDefault();
            setIsShowModal(true);
          }}>
            {isShowModal && (
              <i className="fa fa-spinner fa-spin ms-3"></i>
            )}
            {!isShowModal && (
              <i className="fa fs-3 fa-list ms-3"></i>
            )}
          </a>
          <ApprovalX
            title={title}
            titleOverride={true}
            show={isShowModal}
            schema={schema}
            onHide={() => {
              setIsShowModal(false);
            }}
            onSubmit={(data: any) => {
            }}
            isLoading={false}
            source={source}
            object_id={object_id}
            company_id={company_id}
            code={code}
            readOnly={true}
          />
        </>
      )}

    </>
  );
};

export {
  ApprovalX,
  ButtonProsesApproval,
  ButtonDetailApprovalOnTable
};