import React, {useEffect, useState} from 'react'
import { Nav } from "rsuite";
import GambaranUmumDuaTahapTab from "./GambaranUmumDuaTahapTab";
import AdministrasiKomersilDuaTahapTab from "./AdministrasiKomersilDuaTahapTab";
import { ResumeEvaluasiTab } from "../ResumeEvaluasiTab";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { getEnv } from "../../../../../../../../helpers/procurex";
import { useAxiosPost } from "../../../../../../../../hooks/useAxios";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import BottomToolbarWrapper from "../../../../../../../components/shared/BottomToolbarWrapper";
import {ToolbarInterface, useProposalTender} from '../../../core/_proposalTenderProvider'
import {EvaluasiUserTab} from '../evaluasi_satu_sampul/EvaluasiUserTab'
import KlarifikasiVendorTab from '../evaluasi_satu_sampul/KlarifikasiVendorTab'

/**
 * Digunakan untuk metode_penyampaian 2 tahap
 * @param proposalTender
 * @param onQuotationChanged
 * @constructor
 */
const EvaluasiKomersilDuaTahapTab = ({proposalTender,onQuotationChanged,isOwner}:any) => {
  const [selectedTab, setSelectedTab] = useState("gambaran-umum");
  const [menuTab, setMenuTab] = useState([
    { eventKey: "gambaran-umum", label: "Gambaran Umum" },
    { eventKey: "administrasi-teknis", label: "Komersil" },
    { eventKey: "evaluasi", label: "Resume Evaluasi" },
  ]);
  const intl = useIntl();
  const  [{ doSubmit, isLoading }] = useAxiosPost(
    getEnv("tender") + `/evaluation/administration/syarat`
  )
  const [isLoadingCheck, setIsLoadingCheck] = useState(false);


  const showTab = () => {
    switch (selectedTab) {
      case "gambaran-umum":
        return <GambaranUmumDuaTahapTab proposalTender={proposalTender} jadwalDescription={"Penawaran Komersil"} isOnMenuKomersil = {true}/>;

      case "administrasi-teknis":
        return <AdministrasiKomersilDuaTahapTab
          proposalTender={proposalTender}
          onQuotationChanged={()=>{}}
          isOwner={isOwner}
        />;
      case "evaluasi":
        return <ResumeEvaluasiTab proposalTender={proposalTender} isKomersil = {true}  />;
      default:
        return <></>;
    }
  }

  const isShowTutupEvaluasiKomersil = () => {
    return proposalTender?.status === "Evaluasi Komersil" &&
      proposalTender?.status_detail === "On Going";
  }

  const checkIsAllEvaluate = async() => {
    setIsLoadingCheck(true);
    let state = true;
    let metode_penyampaian = proposalTender?.metode_penyampaian;
    const {data} = await axios.get(getEnv("tender") + `/evaluation/quotation/${proposalTender.uuid}?perPage=1000`)

    data?.data?.forEach((item:any) => {
      if((item?.vendor?.status_evaluation_administration !== "FAILED" && item?.vendor?.status_evaluation_technical !== "FAILED")){
        //override if commercial no data
        if(item?.vendor?.status_evaluation_commercial === "NO-DATA") return;

        if(item?.vendor?.status_evaluation === "NOT-EVALUATE") state = false;
      }
    });
    setIsLoadingCheck(false);
    return state;
  }

  const onTutupEvaluasiKomersil = async() => {
    const isValid = await checkIsAllEvaluate();
    if(!isValid){
      toast.error(intl.formatMessage({id:"Evaluasi vendor belum lengkap."}))
      return false;
    }

    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan menutup"+" "+proposalTender?.status}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Submit evaluasi vendor
        doSubmit(
          {},
          getEnv("tender") + `/evaluation/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          // submit evaluasi administrasi
        }).finally(() => {
          onQuotationChanged && onQuotationChanged();
        });
      } else {
        onQuotationChanged && onQuotationChanged();
      }
    });
  }

  const isShowBidOpening = () => {
    return proposalTender?.status === "Bid Opening" &&
      (proposalTender?.status_detail === "Bid Opening" ||
        proposalTender?.status_detail === "Rejected");
  }
  const onBidOpening = () => {
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan membuka penawaran"}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Ya, submit",
      cancelButtonText: "Tidak"
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        doSubmit(
          {},
          getEnv("tender") + `/bid/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          onQuotationChanged && onQuotationChanged();
        }).finally(() => {
        });
      } else {
      }
    });
  }
  const isShowTutupEvaluasiAdministrasi = () => {
    return (proposalTender?.status === "Evaluasi Administrasi" || proposalTender?.status === "Evaluasi Komersil")  && proposalTender?.status_detail === "On Going";
  }

  const onTutupEvaluasiAdministrasi = async() => {
    const isValid = await checkIsAllEvaluate();
    if(!isValid){
      toast.error(intl.formatMessage({id:"Evaluasi vendor belum lengkap."}))
      return false;
    }
    Swal.fire({
      title: intl.formatMessage({id:"Apakah Anda yakin"})+"?",
      text: intl.formatMessage({id:"Anda akan menutup"+" "+proposalTender?.status}),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: intl.formatMessage({id:"Ya, Submit"}),
      cancelButtonText: intl.formatMessage({id:"Tidak"})
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Submit evaluasi vendor
        doSubmit(
          {},
          getEnv("tender") + `/evaluation/submit/${proposalTender.uuid}`,
          toast
        ).then((res: any) => {
          // submit evaluasi administrasi
        }).finally(() => {
          onQuotationChanged && onQuotationChanged();
        });
      } else {
        onQuotationChanged && onQuotationChanged();
      }
    });
  }

  return (
    <>
      <div className={""}>
        <div className={"d-flex justify-content-between"}>
          <div><h3>Evaluasi Komersil</h3></div>
        </div>
        <hr />

        <Nav appearance={"subtle"} activeKey={selectedTab} onSelect={(e:any)=>{setSelectedTab(e)}} style={{ marginBottom: 20 }}>
          {menuTab.map((item:any, index:number) => {
            return (
              <Nav.Item eventKey={item.eventKey} key={index}>
                {item.label}
              </Nav.Item>
            );
          })}
        </Nav>

        {showTab()}

        <BottomToolbarWrapper isOwner={isOwner} onGoBack={()=>{}} children={
          <>
            {isShowTutupEvaluasiKomersil() && (
              <button
                type={"button"}
                disabled={isLoading}
                className={"btn btn-primary me-3"}
                onClick={onTutupEvaluasiKomersil}
              >
                {(isLoading && isLoadingCheck) ? (
                  <Spinner animation="border" role="status" size={"sm"} />
                ) : (
                  <i className={"fa fa-envelope"}></i>
                )}
                {intl.formatMessage({id:"Tutup"+" "+proposalTender?.status})}
              </button>
            )}
          </>
        } />
      </div>
    </>
  );
};

const EvaluasiKomersilDuaTahapPage = ({proposalTender, onQuotationChanged,isOwner}:any) => {
  const {toolbarEvaluasi, setToolbarEvaluasi} = useProposalTender()

  useEffect(() => {
    setToolbarEvaluasi((prevValue: ToolbarInterface) => ({
      ...prevValue,
      show: true
    }));
  }, [])

  return (
    <>
      <>
        {toolbarEvaluasi?.currentTab === 0 && (
          <EvaluasiKomersilDuaTahapTab
            proposalTender={proposalTender}
            onQuotationChanged={onQuotationChanged}
            isOwner={isOwner}
          />
        )}

        {toolbarEvaluasi?.currentTab === 1 && (
          <EvaluasiUserTab
            proposalTender={proposalTender}
          />
        )}

        {toolbarEvaluasi?.currentTab === 2 && (
          <KlarifikasiVendorTab
            proposalTender={proposalTender}
          />
        )}
      </>
    </>
  )
}


export default EvaluasiKomersilDuaTahapPage
