import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { MapContainer, TileLayer, Marker, Polyline, LayersControl, Popup, useMapEvents } from 'react-leaflet';
import { useState, useEffect, useRef } from 'react'
import { getEnv } from "../../../../helpers/procurex";
import L, { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import "leaflet-defaulticon-compatibility";
import axios from 'axios';

const masterData = {
    title: 'Vessel Management Dashboard',
    path: 'stockview/vessel-management/Dashboard-vessel',
    isSeparator: false,
    isActive: false,
}
const separator = {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
}
const breadcrumbs: Array<PageLink> = [masterData, separator]
const childBreadcrumbs: Array<PageLink> = [
    masterData,
    separator,
    {
        title: 'Stockview Vessel Management Dashboard',
        path: 'stockview/vessel-management/Dashboard-vessel',
        isSeparator: false,
        isActive: false,
    },
    separator,
]

const VesselManagementStockviewPage: React.FC = () => {
    const [vessels, setVessels] = useState([]);
    const [selectedShipId, setSelectedShipId] = useState(null);
    const [polylinesFuture, setPolylinesFuture] = useState([]);
    const colorCustomFutureLine = { color: 'yellow' };
    const { BaseLayer } = LayersControl;

    let apiUrl = process.env.REACT_APP_API_VESSEL;

    const fetchData = async () => {
        try {
            const data = await fetch(apiUrl).then((response) => response.json());

            const coordsArray = data.DATA.map(item => ({
                id: item.SHIP_ID,
                shipName: item.SHIPNAME,
                imo: item.IMO,
                eta: item.ETA,
                destination: item.DESTINATION,
                next_port: item.NEXT_PORT_NAME,
                flag: item.FLAG,
                draught: item.DRAUGHT,
                typeName: item.TYPE_NAME,
                ship_country: item.SHIP_COUNTRY,
                timestamp: item.TIMESTAMP,
                position: [parseFloat(item.LAT), parseFloat(item.LON)]
            }));

            const coordsArrayFuture = await Promise.all(
                data.DATA.map(async (item) => {
                    try {
                        const imo = item.IMO;
                        const port_target_id = item.NEXT_PORT_UNLOCODE;

                        let apiFUTUREUrl = getEnv('vessel_future_routes') + `?msgtype=extended&protocol=jsono&imo=${imo}&port_target_id=${port_target_id}`;
                        const dataFutureRoute = await fetch(apiFUTUREUrl).then((response) => response.json());

                        if (dataFutureRoute.length > 0 && dataFutureRoute[0].code) {
                            console.error('Error in future route API response:', dataFutureRoute[0].detail);
                            return [];
                        }

                        return dataFutureRoute.map(futureItem => {
                            const linestring = futureItem.FINAL_PATH;
                            const matches = linestring.match(/\(([^)]+)\)/);
                            if (matches && matches[1]) {
                                const coords = matches[1];
                                const parsedCoords = coords.split(', ').map(coord => {
                                    const [lng, lat] = coord.split(' ').map(parseFloat);
                                    return { lat, lng };
                                });
                                return {
                                    SHIP_ID: item.SHIP_ID,
                                    coordinates: parsedCoords
                                };
                            }
                            return {
                                SHIP_ID: item.SHIP_ID,
                                coordinates: []
                            };
                        });
                    } catch (error) {
                        console.error('Error fetching future route data for ship:', item.SHIP_ID, error);
                        return [];
                    }
                })
            );

            const flatCoordsArrayFuture = coordsArrayFuture.flatMap(route =>
                route.flatMap(futureRoute =>
                    futureRoute.coordinates.map(coord => ({ ...coord, SHIP_ID: futureRoute.SHIP_ID }))
                )
            );

            setVessels(coordsArray);
            setPolylinesFuture(flatCoordsArrayFuture);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [popupData, setPopupData] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchPopupData = async (imo) => {
        setLoading(true);
        try {
            const response = await axios.get(`${getEnv("stockview")}/transportation-all?imo=${imo}`);

            if (response.data && response.data.data && response.data.data.length > 0) {
                setPopupData(response.data.data);
            } else {
                setPopupData([]);
            }
        } catch (error) {
            console.error('Error fetching popup data:', error);
            setPopupData(null);
        } finally {
            setLoading(false);
        }
    };

    const handleMarkerClick = async (shipId, imo) => {
        setSelectedShipId(shipId);
        await fetchPopupData(imo);
    };

    const MapClickHandler = () => {
        useMapEvents({
            click: () => {
                setSelectedShipId(null);
            },
        });
        return null;
    };

    const customIcon = new L.Icon({
        iconUrl: 'https://img.icons8.com/?size=100&id=aVcMNH8jI87A&format=png&color=000000',
        iconSize: [70, 70],
        iconAnchor: [12, 12],
        popupAnchor: [0, -12]
    });

    const mapRef = useRef<L.Map | null>(null);

    const handleVesselSelect = (e) => {
        const vesselId = e.target.value;
        setSelectedShipId(vesselId);
        const selectedVessel = vessels.find((vessel) => vessel.id === vesselId);

        if (selectedVessel && mapRef.current) {
            mapRef.current.flyTo(selectedVessel.position, 10);
        } else if (vesselId == '') {
            mapRef.current.flyTo([-1.869386, 109.700603], 5);
        }
    };

    //laycan date format
    const formatDateLaycan = (dateString) => {
        if (!dateString) {
            return '';
        }

        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const formattedStartDate = formatDateLaycan(
        (popupData && popupData.length > 0 && popupData[0]?.laycan_start_date) || ''
    );

    const formattedEndDate = formatDateLaycan(
        (popupData && popupData.length > 0 && popupData[0]?.laycan_end_date) || ''
    );
    //end laycan date format

    function formatNumber(value) {
        if (!value) return '';

        return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    index
                    element={
                        <div>
                            <div className='row'>
                                <div className='mb-2 col-3'>
                                    <ul className='list-group'>
                                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                                            <strong>Jumlah Vessel Yang Tampil</strong>
                                            <span className='badge badge-primary badge-pill'>{vessels.length} Vessel</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className='mb-2 col-3'>
                                    <select className="form-select" onChange={handleVesselSelect} defaultValue="">
                                        <option value="" >No Selected Vessel</option>
                                        {vessels.map((vessel, index) => (
                                            <option key={index} value={vessel.id}>
                                                {vessel.shipName} - {vessel.imo}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <MapContainer
                                center={[-1.869386, 109.700603]}
                                zoom={5}
                                style={{ height: "620px", width: "100%" }}
                                ref={mapRef}
                            >
                                <LayersControl position="topright">
                                    <BaseLayer checked name="Satelite">
                                        <TileLayer
                                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                        />
                                    </BaseLayer>
                                    <BaseLayer name="Basic">
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                        />
                                    </BaseLayer>
                                </LayersControl>

                                <MapClickHandler />
                                {vessels.map((vessel, index) => (
                                    <Marker
                                        key={index}
                                        position={vessel.position}
                                        icon={customIcon}
                                        eventHandlers={{
                                            click: () => handleMarkerClick(vessel.id, vessel.imo),
                                        }}
                                    >
                                        <Popup>
                                            {loading ? (
                                                <div>Loading...</div>
                                            ) : popupData && popupData.length > 0 ? (
                                                <div style={{ width: '200px' }}>
                                                    <h2>{vessel.shipName}</h2>
                                                    <ul className='list-group'>
                                                        <li className='list-group-item'>
                                                            <span>Harga Perolehan : <br /></span> <strong>{formatNumber(popupData[0].harga_perolehan) || 'N/A'} {popupData[0].currency}</strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>Komoditas : </span> <strong>{popupData[0].komoditas || 'N/A'}</strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>Kapasitas : </span> <strong>{formatNumber(popupData[0].capacity) || 'N/A'} {popupData[0].uom || ''}</strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>Vendor : </span> <strong>{popupData[0].vendor || 'N/A'}</strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>Awarding : </span> <strong>{popupData[0].awarding || 'N/A'}</strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>ETA : </span>
                                                            <strong>
                                                                {(() => {
                                                                    const dateString = vessel.eta;
                                                                    if (!dateString) return 'N/A';
                                                                    const date = new Date(dateString);
                                                                    if (isNaN(date.getTime())) return 'N/A';
                                                                    const day = String(date.getDate()).padStart(2, '0');
                                                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                                                    const year = date.getFullYear();
                                                                    const hours = String(date.getHours()).padStart(2, '0');
                                                                    const minutes = String(date.getMinutes()).padStart(2, '0');
                                                                    const seconds = String(date.getSeconds()).padStart(2, '0');

                                                                    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                                                                })()}
                                                            </strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>ETA Estimasi: </span>
                                                            <strong>
                                                                {(() => {
                                                                    const dateString = popupData[0].eta_estimasi;
                                                                    if (!dateString) return 'N/A';
                                                                    const date = new Date(dateString);
                                                                    if (isNaN(date.getTime())) return 'N/A';
                                                                    const day = String(date.getDate()).padStart(2, '0');
                                                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                                                    const year = date.getFullYear();

                                                                    return `${day}/${month}/${year}`;
                                                                })()}
                                                            </strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>Ship Destination : </span> <strong>{vessel.next_port || 'N/A'}</strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>Laycan Estimasi : </span> <strong>{formattedStartDate || 'N/A'}</strong> - <strong>{formattedEndDate || 'N/A'}</strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>Port Load : </span> <strong>{popupData[0].port_load || 'N/A'}</strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>Port Destination : </span> <strong>{popupData[0].port_destination || 'N/A'}</strong>
                                                        </li>
                                                    </ul>
                                                    <span>Terakhir Diupdate MT : <br />
                                                        <strong>
                                                            {(() => {
                                                                const dateString = vessel.timestamp;
                                                                if (!dateString) return 'N/A';
                                                                const date = new Date(dateString);
                                                                if (isNaN(date.getTime())) return 'N/A';
                                                                const day = String(date.getDate()).padStart(2, '0');
                                                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                                                const year = date.getFullYear();
                                                                const hours = String(date.getHours()).padStart(2, '0');
                                                                const minutes = String(date.getMinutes()).padStart(2, '0');
                                                                const seconds = String(date.getSeconds()).padStart(2, '0');

                                                                return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                                                            })()}
                                                        </strong>
                                                    </span>
                                                </div>
                                            ) : (
                                                <div style={{ width: '200px' }}>
                                                    <h2>{vessel.shipName}</h2>
                                                    <ul className='list-group'>
                                                        <li className='list-group-item'>
                                                            <span>ETA :</span> <strong>{vessel.eta || 'N/A'}</strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>Destination :</span> <strong>{vessel.destination || 'N/A'}</strong>
                                                        </li>
                                                        <li className='list-group-item'>
                                                            <span>Sisa Data Tidak Ada Dikarenakan Imo/Kapal Belum Diinputkan di Masterdata Transportation</span>
                                                        </li>
                                                    </ul>
                                                    <span>Terakhir Diupdate MT : <br />
                                                        <strong>
                                                            {(() => {
                                                                const dateString = vessel.timestamp;
                                                                if (!dateString) return 'N/A';
                                                                const date = new Date(dateString);
                                                                if (isNaN(date.getTime())) return 'N/A';
                                                                const day = String(date.getDate()).padStart(2, '0');
                                                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                                                const year = date.getFullYear();
                                                                const hours = String(date.getHours()).padStart(2, '0');
                                                                const minutes = String(date.getMinutes()).padStart(2, '0');
                                                                const seconds = String(date.getSeconds()).padStart(2, '0');

                                                                return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                                                            })()}
                                                        </strong>
                                                    </span>
                                                </div>
                                            )}
                                        </Popup>
                                    </Marker>
                                ))}

                                {selectedShipId && (
                                    <Polyline
                                        pathOptions={colorCustomFutureLine}
                                        positions={polylinesFuture
                                            .filter(item => item.SHIP_ID === selectedShipId)
                                            .map(item => [item.lat, item.lng])
                                        }
                                    />
                                )}
                            </MapContainer>
                        </div>
                    }
                />
            </Route>
        </Routes>
    );
}

export default VesselManagementStockviewPage;
